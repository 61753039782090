import React from 'react'
import { View, StyleSheet, Dimensions, Animated, ScrollView, TouchableOpacity, Text } from 'react-native'
import { defaultColor } from '../../utils/GlobalStyles'
import { StationContext } from '../../utils/Hooks'
import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
import RadioIcon from '@material-ui/icons/Radio';
import LayersIcon from '@material-ui/icons/Layers';
import MailIcon from '@material-ui/icons/Mail';

const s = StyleSheet.create({
	container: {
		position:'absolute',
		right: 0,
		top: 60,
		maxWidth: 340,
		width: '100%',
		alignItems: 'flex-end',
		justifyContent: 'flex-end'
	},
	animCtn: {
		height: '100%',
		width: '100%',
		marginRight:20
	},
	headerCtn: {
		alignItems: 'center',
		justifyContent: 'center',
		borderBottomWidth: 1,
		borderColor: 'rgba(255,255,255,0.2)',
		paddingBottom: 12,
		paddingBottom: 8
	},
	header: {
		color: '#00EBFF',
		fontWeight: 'bold'
	},
	blur: {
		backgroundColor: 'rgba(0,0,0,0.5)',
		borderRadius: 25,
		height:'100%',
		width: '100%'
	},
	itemContainer: {
		height: 40,
		width: '100%',
		borderBottomWidth: 1,
		borderColor: 'rgba(255,255,255,0.2)',
		flexDirection: 'row',
		alignItems: 'center',
		paddingLeft: 80
	},
	itemText: {
		color: 'white',
		fontWeight: 'bold',
		fontSize: 14
	}
})

const badgeStyle = {
	backgroundColor: defaultColor
}

//Search
export default ({ display }) => {
	const { width } = Dimensions.get('window')
	const anim = React.useRef( new Animated.Value(1)).current

	const [show, setShow] = React.useState(false)

	React.useEffect(() => {
		if(display){ setShow(true)}
		Animated.timing(anim, {
			toValue: display ? 0 : 1,
			duration: 100
		}).start(() => {
			if(display == false){ setShow(false)}
		})
	},[display])

	return(
		<View style={[s.container, {bottom: width >= 640 ? 170 : 234, display: show ? 'inherit' : 'none'}]}>
			<Animated.View style={[
				s.animCtn,
				{transform: [{translateX: anim.interpolate({inputRange: [0,1], outputRange:['0%','100%']})}]}
			]}>
				<View style={[s.blur, {padding:10}]}>
					<View style={s.headerCtn}><Text style={s.header}>Settings</Text></View>
					<ScrollView style={{flex:1}}>
						<TouchableOpacity onPress={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSdAcct7y11p0x22Sq1vvCVirHIgIU7os7mTqIdBrTBuSp-SiA/viewform?usp=sf_link','_blank')}>
							<View style={s.itemContainer}>
								<LibraryMusicIcon style={{color:"white", marginRight: 20}}/>
								<Text numberOfLines={1} style={s.itemText}>Submit Music</Text>
							</View>
						</TouchableOpacity>
						<TouchableOpacity onPress={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLScx9Su_aTeSthTV3BrYdv1MqRSkT_ZI0lGKdkLZKs0aELYnew/viewform?usp=sf_link', '_blank')}>
							<View style={s.itemContainer}>
								<RadioIcon style={{color:"white", marginRight: 20}}/>
								<Text numberOfLines={1} style={s.itemText}>Create a Station</Text>
							</View>
						</TouchableOpacity>
						<TouchableOpacity onPress={() => window.open(`https://www.privacypolicies.com/live/aa97fcff-9986-4be6-8805-e829ee4c0bbe`, '_blank')}>
							<View style={s.itemContainer}>
								<LayersIcon style={{color:"white", marginRight: 20}}/>
								<Text numberOfLines={1} style={s.itemText}>Privacy Policy</Text>
							</View>
						</TouchableOpacity>
						<TouchableOpacity onPress={() => window.open('mailto:contact@joinaudience.com', '_blank')}>
							<View style={s.itemContainer}>
								<MailIcon style={{color:"white", marginRight: 20}}/>
								<Text  numberOfLines={1} style={s.itemText}>Contact Us</Text>
							</View>
						</TouchableOpacity>
					</ScrollView>
				</View>
			</Animated.View>
		</View>
	)
}
import AcUnitIcon from '@material-ui/icons/AcUnit'
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied'
import SpaIcon from '@material-ui/icons/Spa'
import WavesIcon from '@material-ui/icons/Waves'
import BlurOnIcon from '@material-ui/icons/BlurOn'
import BeachAccessIcon from '@material-ui/icons/BeachAccess'
import BubbleChartIcon from '@material-ui/icons/BubbleChart'
import InvertColorsIcon from '@material-ui/icons/InvertColors'
import AdjustIcon from '@material-ui/icons/Adjust'
import Brightness1Icon from '@material-ui/icons/Brightness1';
import TonalityIcon from '@material-ui/icons/Tonality'
import FilterHdrIcon from '@material-ui/icons/FilterHdr';

const icons = {
	snowflake: AcUnitIcon,
	sadFace: SentimentVeryDissatisfiedIcon,
	plant: SpaIcon,
	waves: WavesIcon,
	bubbles: BubbleChartIcon,
	umbrella: BeachAccessIcon,
	fadeBubbles: BlurOnIcon,
	droplet: InvertColorsIcon,
	circleDot: AdjustIcon,
	circle: Brightness1Icon,
	tonality: TonalityIcon,
	mountain: FilterHdrIcon
}

export default icons
import React from 'react'
import { View, Text, Image, StyleSheet } from 'react-native'
import { StationContext } from '../../utils/Hooks'
import equalizer from '../../assets/equalizer.gif'
import defaultAvatar from '../../assets/DefaultAvatar.png'

const s = StyleSheet.create({
	container: {
		//maxWidth: '33%',
		width: '100%',
		height: 64,
		flex:1,
		flexDirection: 'row',
	},
	textContainer: {
		flex:1,
		//justifyContent: 'center',
		padding: 10
	},
	nowPlayingText: {
		color: '#00EBFF',
		fontWeight: 'bold',
		fontSize: 14,
		marginBottom: 4
	},
	titleText: {
		color: 'white',
		fontSize:14,
		alignText: 'left'
	},
	equalizer: {
		height: 10,
		width: 40,
		marginTop: 4
	},
	addBtn: {
		//justifyContent: 'center',
		//alignItems: 'center',
		height: 64,
		width: 36,
	}
})

const NowPlaying = ({ title, artist, albumArtUrl, link }) => {
	const [isHovering, setIsHovering] = React.useState(false)
	const { stationID } = React.useContext(StationContext)
	const text = title && artist ? `${title} • ${artist}`: "nothing playing . . ."

	return (
		<View style={s.container}>
			<Image style={{resizeMode:'contain', height:64, width: 64, backgroundColor: 'black'}} source={albumArtUrl ? {uri:albumArtUrl} : defaultAvatar} />
			<View style={s.textContainer}>
				<View style={{flexDirection: 'row'}}>
					<View><Text style={s.nowPlayingText}>Now Playing</Text></View>
					<Image style={s.equalizer} resizeMode="contain" source={equalizer} />
				</View>
				<View style={{width: '100%', alignItems:'flex-start'}}><Text style={[s.titleText,{textDecoration: isHovering ? 'underline' : null}]} onMouseEnter={()=>setIsHovering(true)} onMouseLeave={()=>setIsHovering(false)} onPress={() => window.open(link,"_blank")}>{stationID === "whitenoise" ? "White Noise" :text}</Text></View>
				{/*<TextTicker animationType="bounce" duration={200*text.length} easing={Easing.easeInOut} style={s.titleText}>{text}</TextTicker>*/}
			</View>
			{/*<Icon
				name={isSaved ? "check-circle" : "content-save"}
				type="material-community"
				color='#00EBFF'
				containerStyle={s.addBtn}
			/>
			<SaveSongPrompt data={songData} display={showSavePrompt} isSaved={isSaved} onPress={handleOnSave} dismiss={() => setShowSavePrompt(false)}/>*/}
		</View>
	)
}

export default NowPlaying
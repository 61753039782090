import React from 'react'
import { StyleSheet, TouchableOpacity } from 'react-native'
import Avatar from '@material-ui/core/Avatar';
import defaultAvatar from '../../assets/DefaultAvatar.png'

const s = StyleSheet.create({
	container: {
		height: 40,
		alignItems: 'center',
		flexDirection: 'row',
		borderRadius: 100,
		overflow: 'hidden'
	},

})

const UserInfo = ({name, color, bitmojiUrl, onPress }) => {
	//constdocument.getElementById("bitmoji") && document.getElementById("bitmoji").src
	return (
		<TouchableOpacity style={s.container} onPress={onPress}>
			{/*<img id="bitmoji" src={bitmojiUrl || defaultAvatar} height={40} width={40}/>*/}
			<Avatar alt={name} src={bitmojiUrl} />
			
			{/*<AvatarPill size="medium" name={name} color={color} bitmojiUrl={bitmojiUrl}/>*/}
		</TouchableOpacity>
	)
}
	
export default UserInfo
import React from 'react'
import { View, StyleSheet, Dimensions, Text, TouchableWithoutFeedback, TouchableOpacity, ActivityIndicator, Animated } from 'react-native'
import { UserContext, StationContext } from '../../utils/Hooks'
import firebase, { storageRef, firestoreRef, authRef, getSnapToken } from '../../config/Firebase'
import ProfileEdit from './ProfileEdit'
import { useParams } from 'react-router-dom'

const s = StyleSheet.create({
	container: {
		height: '100%',
		width: '100%',
		marginRight:20
	},
	headerCtn: {
		alignItems: 'center',
		justifyContent: 'center',
		borderBottomWidth: 1,
		borderColor: 'rgba(255,255,255,0.2)',
		paddingBottom: 12,
		paddingBottom: 8
	},
	header: {
		color: '#00EBFF',
		fontWeight: 'bold'
	},
	blur: {
		backgroundColor: 'rgba(0,0,0,0.5)',
		borderRadius: 25	
	},
	inputContainer: {
		width: '100%',
		flexDirection: 'row',
		marginVertical: 10
	},
	textInputContainer: {
		flex: 1,
		height:30,
		borderColor:  'gray',
		borderRadius: 100,
		borderWidth: 1,
		paddingHorizontal: 6,
		flexDirection: 'row',
		alignItems: 'center'
	},
	sendBtn: {
		borderRadius: 100,
		//borderWidth: 1,
		marginLeft: 10
	},
	textInput: {
		width: '100%',
		height: '100%',
		color: 'white',
	},
	stationItemContainer: {
		paddingLeft: 2,
		paddingRight: 6,
		borderRadius:100,
		alignItems: 'center',
		borderWidth: 1,
		borderColor: 'white',
		marginBottom: 4,
		flexDirection: 'row',
		height: 30,
		//justifyContent: 'center'
	},
	tagTextContainer: {
		paddingLeft: 4,
		//alignItems: 'center',
		flexDirection: 'row',
	},
	tagText:{
		fontWeight: 'bold',
		fontStyle: 'italic',	
		color: 'rgba(255, 255, 255,0.5)',
	},
	tagsContainer: {
		flexDirection: 'row',
		paddingRight: 4,
		overflow: 'hidden',
		flex: 1,
		paddingLeft: 10
	},
	outerContainer: {
		height: '100%',
		width: '100%',
	},
	innerContainer: {
		justifyContent: 'center',
		height: '100%',
		width: '100%'
	},
	skipBtn: {
		backgroundColor: 'lightgray',
		justifyContent: 'center',
		alignItems: 'center',
		height: 68,
		width: 284,
		borderRadius: 100,
		marginTop: 20
	},
	backText: {
		fontWeight: 600,
		fontSize: 16
	},
	errorText: {
		fontSize: 14,
		color: 'red',
		margin: 4,
		textAlign: 'center'
	},
	caption: {
		fontSize:18,
		fontWeight:'bold',
		color: 'white',
		marginTop: 10,
	},
	headline: {
		fontSize:24,
		fontWeight:'bold',
		color: 'white',
		marginTop: 10,
	},
	snapchatBtn: {
		width: '90%',
		height: 40,
		backgroundColor: 'yellow',
		textAlign: 'center',
		borderRadius: 100,
	}
})

export default ({dismiss, display, setShow}) => {
	const { slug } = useParams()
	const { width, height } = Dimensions.get('window')
	const { user, userData, setUserData } = React.useContext(UserContext)
	const { stationData } = React.useContext(StationContext)
	const snapBtnRef = React.useRef({})

	const [errorMsg, setErrorMsg] = React.useState(null)
	const [loading, setIsLoading] = React.useState(false)
	
	const anim = React.useRef( new Animated.Value(0)).current

	const handleSnapBtnPress = () => {
		window.location.href = 'https://us-central1-sparkdj-livestream.cloudfunctions.net/snap/getRedirect?stationName=' + slug
	}

	React.useEffect(() => {
		//window.snapKitInit = function () {
        var loginButtonIconId = "login-button";
        var loginParamsObj = {
        	handleAuthGrantFlowCallback: function handleAuthGrantFlowCallback() {

        	},
        	clientId: "33ed02c6-5a2f-4b5f-a7b6-edb1277452d8",
        }
        // Mount Login Button
        /*var scopes = [
            "user.display_name",
            "user.bitmoji.avatar",
            "user.external_id",
        ]

        window.snap.loginkit.mountButton(loginButtonIconId, {
          clientId: "33ed02c6-5a2f-4b5f-a7b6-edb1277452d8",
          redirectURI: 'http://localhost:3000',
          scopeList: scopes,
          scopes: scopes.join(" "),
          handleResponseCallback: function () {
            let token, _user
            //console.log(window.snap)
            setErrorMsg(null)
            setIsLoading(true)
            window.snap.loginkit.fetchUserInfo()
              .then(result => { 
                  console.log("User info:", result);
                  //Upload Avatar
                  return getSnapToken({id: result.data.me.externalId, displayName: result.data.me.displayName})
                  .then(res => authRef.signInWithCustomToken(res.data))
                  .then(res => {
                    _user = res.user
                    return fetch(result.data.me.bitmoji.avatar)
                  })
              })	
              .then(response => response.blob())
              .then(blob => storageRef.child(`/users/${_user.uid}/bitmojiAvatar.png`).put(blob))
              .then(snapshot => snapshot.ref.getDownloadURL())
              .then(url => {
                console.log("Avatar Url: ", url)
                return firestoreRef.collection('users').doc(_user.uid).set({
                    bitmojiUrl: url
                },{merge:true})
              })
              .catch(err => {
                console.log(err)
                setIsLoading(false)
                setErrorMsg("Error signing in. Refresh and try again")
              })
            },
        });	

      };

      // Load the SDK asynchronously
      (function (d, s, id) {
        var js,
          sjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = "https://sdk.snapkit.com/js/v1/login.js";
        sjs.parentNode.insertBefore(js, sjs);
      })(document, "script", "loginkit-sdk");*/
	},[window.location.path])

	React.useEffect(() => {
		if(userData.snapID){
			setIsLoading(false)
		}
	},[userData.snapID])

	React.useEffect(() => {
		if(display == true){ setShow(true)}
		Animated.timing(anim, {
			toValue: display ? 0 : 1,
			duration: 100
		}).start(() => {
			if(display == false){ setShow(false)}
		})
	},[display])

	return (
		<Animated.View style={[
			s.container,
			{transform: [{translateX: anim.interpolate({inputRange: [0,1], outputRange:['0%','100%']})}]}
		]}>
			<View style={[s.outerContainer, s.blur, {padding:10, display: display ? 'inherit' : 'none'}]}>
				<View style={[s.innerContainer,{display: userData.snapID == null ? 'inherit' : 'none'}]}>
					<View style={s.headerCtn}><Text style={s.header}>Sign In</Text></View>
					{loading ? 
						<View style={{alignItems:'center', flex:1, justifyContent: 'center'}}>
							<ActivityIndicator size="large" color="gold"/>
							<Text style={s.caption}>Waiting for response from server</Text>
						</View>
						:<View style={{paddingLeft:30, flex:1, justifyContent: 'center'}}>
							<Text style={s.caption}>• Bitmoji Avatar</Text>
							{/*<Text style={s.caption}>• Create, Share, and Join Rooms</Text>*/}
							<Text style={s.caption}>• Same profile across devices</Text>
						</View>
					}
				    <div ref={ref => snapBtnRef.current = ref} id="login-button"></div>
				    <TouchableOpacity disabled style={s.snapchatBtn} onPress={handleSnapBtnPress}>
				    	<Text>Coming Soon</Text>
				    </TouchableOpacity>
				   	<Text style={s.errorText}>{errorMsg}</Text>
				</View>
				<View style={[s.innerContainer,{display: userData.snapID == null ? 'none' : 'inherit'}]}>
					<View style={s.headerCtn}><Text style={s.header}>Profile</Text></View>
					<ProfileEdit dismiss={dismiss}/>
				</View>
			</View>
		</Animated.View>
	)
}
import Auth from './AuthContent'
import Consumer from './ConsumerContent'
import Business from './BusinessContent'

const NoContent = () => null

export default {
	Auth: Auth,
	Consumer: Consumer,
	Business: Business,
	NoContent: NoContent,
}
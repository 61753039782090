import React, {useContext} from 'react'
import { View, Text, Image, StyleSheet, TextInput,ActivityIndicator, Dimensions } from 'react-native'
import { NavigationContext, UserContext } from '../utils/Hooks'
import Modal from '@material-ui/core/Modal';
import g from '../utils/GlobalStyles'
import StyledButton from '../components/CustomComponents/StyledButton'
import firebase from '../config/Firebase'
import { defaultColor } from '../utils/GlobalStyles'
import SendIcon from '@material-ui/icons/Send';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import gmailIconImg from '../assets/gmailIcon.png'
import Fab from '@material-ui/core/Fab';
import DialogContent from '@material-ui/core/DialogContent';

const s = StyleSheet.create({
	container: {
		width: '100%',
		height: Dimensions.get('window').height,
		alignItems: 'center',
		justifyContent: 'center'
	},
	textInputContainer: {
		width: '100%',
		borderColor:  'white',
		borderRadius: 100,
		borderWidth: 1,
		paddingHorizontal: 6,
		height: 32,
		maxWidth: 340,
		marginBottom: 10
	},
	caption: {
		fontSize: 14,
		fontWeight: 'bold',
		color: 'white',
		marginBottom: 10,
	},
	usernameInput: {
		width: '100%',
		height: '100%',
		color: defaultColor,
		fontWeight: 'bold',
	},
})

const defaultPlaceholder = "Just your email address"

export default () => {
	const { navigateTo, isBusiness, setShowLoading } = useContext(NavigationContext)
	const { setUser, userInit } = useContext(UserContext)

	const [email, setEmail] = React.useState("")
	const [placeholder, setPlaceholder] = React.useState(defaultPlaceholder)
	const [color, setColor] = React.useState("gray")
	const [isAuthenticating, setIsAuthenticating] = React.useState(false)
	const [isSending, setIsSending] = React.useState(false)

	React.useEffect(() => {
		setShowLoading(true)
		return () => setShowLoading(false)
	},[])

	const handleOnSendClick = () => {
		setIsSending(true)
		firebase.auth().sendSignInLinkToEmail(email, {  url: 'https://earlyaccess.sparkdj.com', handleCodeInApp: true,})
		  .then(function() {
		    window.localStorage.setItem('emailForSignIn', email);
		    setColor("lightgreen")
		    setPlaceholder("Email sent. Check your inbox.")
		  })
		  .catch(function(error) {
		  	console.log("Error: ", error)
		  	setColor("red")
		    // Some error occurred, you can inspect the code: error.code
		    setPlaceholder(error.message)
		  }).finally(() => {
		  	setEmail("")
		    setIsSending(false)
		  })
	}

	const handleDifferentCredentialError = err => {
		return firebase.auth().fetchProvidersForEmail(err.email)
			.then(providers => {
				if (providers.indexOf("google.com") >= 0){

				} else if (providers.indexOf("facebook.com") >= 0){

				}
			})
	}

	const signInWithProvider = provider => {
		setIsAuthenticating(true)
		return firebase.auth().signInWithRedirect(provider)
			.then(result => {
				setUser(result.user)
				navigateTo(isBusiness ? 'Business' : 'Consumer')
			}).catch(err => {
				console.log(err)
				setIsAuthenticating(false)
			})
	}

	const handleOnGmailPress = () => {
		let provider = new firebase.auth.GoogleAuthProvider()
		return signInWithProvider(provider)
	}

	return (
		<Modal open={userInit}>
			<DialogContent>
				<View style={s.container}>
					<Text style={s.caption}>Sign in with gmail</Text>
					<Fab onClick={handleOnGmailPress}>
						{isAuthenticating ? <ActivityIndicator /> : <img src={gmailIconImg} height={30} />}
					</Fab>
					<View style={{width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', maxWidth: 340}}>
						<View style={{margin: 10, backgroundColor: 'white', height: 1}} />
						<Text style={{color: 'white',paddingVertical: 20, fontWeight: 'bold'}}>OR</Text>
						<View style={{flex:1,marginLeft: 10, backgroundColor: 'white', height: 1}} />
					</View>
					<Text style={s.caption}>Send a sign in link to your email</Text>
					<View style={s.textInputContainer}>			
						<TextInput
							value={email} 
							style={s.usernameInput} 
							placeholder={placeholder}
							onChangeText={text=>setEmail(text)}
							placeholderTextColor={color}
						/>
					</View>
					<Fab onClick={handleOnSendClick}>
						{isSending ? <ActivityIndicator /> : <SendIcon />}
					</Fab>
				</View>
			</DialogContent>
		</Modal>
	)
}
import React from "react"
import { StyleSheet, Text, View, Dimensions } from "react-native"
import Home from './screens/Home'
import ContentCreators from './screens/ContentCreators'
import SplashScreen  from './screens/SplashScreen'
import Navigation from './components/Navigation'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { Howler, Howl } from 'howler'
import silence from './assets/silence_64kb.mp3'

const App = () =>  {
    //console.log('Starting program')
    const { height, width } = Dimensions.get('window')

    React.useEffect(() => {
		const script = document.createElement('script')

		script.type = "text/javascript"
		script.src = "https://cdn.applixir.com/applixir.sdk3.0m.js"
		script.async = true

		document.body.appendChild(script)
		return () => {
			document.body.removeChild(script)
		}
	},[])

    return (
    	<Router>
	        <View style={{height: height, width: '100%'}}>
	        	<Switch>
	        		<Route exact path="/">
	        			<Navigation />
	        		</Route>
	       			<Route path={"/:slug"}>
	            		<Navigation />
	            	</Route>
	        	</Switch>
	        </View>
	    </Router>
    )
}

export default App
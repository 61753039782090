import React from 'react'
import { View, StyleSheet } from 'react-native'
import StationFlipper from './StationFlipper'
import StationTitle from './StationTitle'
import { StationContext } from '../../utils/Hooks'

const s = StyleSheet.create({
	container: {
		width: '100%',
		flex: 1,
	}
})

const Stations = ({ stationList, stationID, setStationID}) => {
	const { showFlipper, isUp, setIsUp } = React.useContext(StationContext)
	const stationData = stationList.filter(station => station.stationID === stationID)[0]
	
	return (
		<View style={s.container}>
			{stationID !== "earn" &&
				<StationFlipper
				stationList={stationList} 
				display={showFlipper}
				stationID={stationID}
				setStationID={setStationID}
				isUp={isUp}
				setIsUp={setIsUp}
			/>}
			<StationTitle stationData={stationData || {}}/>
		</View>
	)
}

export default Stations
import React from 'react'
import { View, ScrollView, StyleSheet, Animated, TextInput, FlatList, Text,TouchableOpacity } from 'react-native'
import { useHistory } from 'react-router-dom'
import { StationContext } from '../../utils/Hooks'
import IconButton from '@material-ui/core/IconButton'
import Circle from '@material-ui/icons/FiberManualRecord';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import { defaultColor } from '../../utils/GlobalStyles'
import SearchIcon from '@material-ui/icons/Search'
import icons from '../../utils/Icons'

const s = StyleSheet.create({
	container: {
		height: '100%',
		width: '100%',
		marginRight:20
	},
	currency: {
		textAlign: 'center',
		justifyContent: 'center',
		alignItems: 'center',
		fontColor: 'gold',
		fontWeight: 'bold',
		paddingLeft: 6,
		paddingRight: 4,
		fontSize: 20
	},
	blur: {
		backgroundColor: 'rgba(0,0,0,0.5)',
		borderRadius: 25	
	},
	inputContainer: {
		width: '100%',
		flexDirection: 'row',
		marginVertical: 10
	},
	textInputContainer: {
		flex: 1,
		height:30,
		borderColor:  'gray',
		borderRadius: 100,
		borderWidth: 1,
		paddingHorizontal: 6,
		flexDirection: 'row',
		alignItems: 'center'
	},
	sendBtn: {
		borderRadius: 100,
		//borderWidth: 1,
		marginLeft: 10
	},
	textInput: {
		width: '100%',
		height: '100%',
		color: 'white',
	},
	stationItemContainer: {
		paddingLeft: 2,
		paddingRight: 6,
		borderRadius:100,
		alignItems: 'center',
		borderWidth: 1,
		borderColor: 'white',
		marginBottom: 4,
		flexDirection: 'row',
		height: 30,
		//justifyContent: 'center'
	},
	stationNameText: {
		color: 'white',
		fontWeight: 'bold',
		marginLeft: 4,
	},
	tagTextContainer: {
		paddingLeft: 4,
		//alignItems: 'center',
		flexDirection: 'row',
	},
	tagText:{
		fontWeight: 'bold',
		fontStyle: 'italic',	
		color: 'rgba(255, 255, 255,0.5)',
	},
	tagsContainer: {
		flexDirection: 'row',
		paddingRight: 4,
		overflow: 'hidden',
		flex: 1,
		paddingLeft: 10
	},
	headerCtn: {
		alignItems: 'center',
		justifyContent: 'center',
		borderBottomWidth: 1,
		borderColor: 'rgba(255,255,255,0.2)',
		paddingBottom: 12,
		paddingBottom: 8
	},
	header: {
		color: '#00EBFF',
		fontWeight: 'bold'
	},
})

const SearchItem = ({item, isCurrent, onPress, isEarn }) => {
	const Icon = item && item.icon ? icons[item.icon] : View
	const name = item && item.name || ""
	const tags = item && item.tags || []
	return (
		<TouchableOpacity onPress={()=>onPress(item.stationID)} style={[s.stationItemContainer, {borderColor: isEarn ? "gold" : 'white', backgroundColor: isCurrent ? (isEarn ? "gold" : defaultColor) : 'rgba(0,0,0,0.7)'}]}>
			{isEarn ? <Text style={[s.currency,{color: isCurrent ? 'black' : 'gold'}]}>ⵙ</Text>: <Icon style={{color: isCurrent ? 'black' : (isEarn ? 'gold' : defaultColor)}}/>}
			<Text style={[s.stationNameText,{color: isCurrent ? 'black' : (isEarn ? 'gold' : 'white')}]}>{name.toUpperCase()}</Text>
			{/*<View style={s.tagsContainer}><Text style={s.tagText}>{isEarn ? "" : "60ⵙ/hr"}</Text></View>*/}
		</TouchableOpacity>
	)
}

//SearchContainer
export default ({ display, stationList, setShow }) => {
	const history = useHistory()
	const { stationID, setStationID, setStationIsLoading, showEarn, setShowEarn } = React.useContext(StationContext)

	const anim = React.useRef( new Animated.Value(1)).current
	const [slug, setSlug] = React.useState("")

	const handleOnPress = (_stationID) => {
		setStationIsLoading(true)
		//setStationID(_stationID)
		for (let i=0;i<stationList.length;i++) {
			if (stationList[i].stationID == _stationID) {
				history.push('/'+stationList[i].slug)
			}
		}
		if (_stationID == "earn"){ 
			history.push('/earn')
		}
	}

	React.useEffect(() => {
		if(display){ setShow(true)}
		Animated.timing(anim, {
			toValue: display ? 0 : 1,
			duration: 100
		}).start(() => {
			if(display == false){ setShow(false)}
		})
	},[display])

	return (
		<Animated.View style={[
			s.container,
			{transform: [{translateX: anim.interpolate({inputRange: [0,1], outputRange:['0%','100%']})}]}
		]}>
			<View style={[s.container, s.blur, {padding:10}]}>
				<View style={s.headerCtn}><Text style={s.header}>Search Stations</Text></View>
				{display !== 0 && <View style={s.inputContainer}>
					<View style={s.textInputContainer}>
						<SearchIcon style={{color: 'white'}}/>
						<TextInput style={s.textInput} onChangeText={text=>setSlug(text)} value={slug} placeholder="Filter stations"/>
					</View>
				</View>}
				<ScrollView style={{flex:1}}>
					{/*<View style={{marginBottom: 20}}><SearchItem item={{icon: 'currency', name: 'earn'}} isCurrent={showEarn} isEarn onPress={()=>handleOnPress("earn")}/></View>*/}
					{stationList.filter(item => slug === "" || item.name.indexOf(slug.toUpperCase()) !== -1).map((item,i) => <SearchItem key={i} item={item} isCurrent={item.stationID === stationID} onPress={handleOnPress}/>)}
				</ScrollView>
			</View>
		</Animated.View>
	)
}



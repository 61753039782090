import React from 'react'


const darkPct = 0.60

const s = {	
	canvas: {
		height: '100%',
		left: 0,
		position: 'fixed',
		top: 0,
		width: '100%',
		zIndex: 100000,
		pointerEvents: 'none',
	}
}

const Noise = props => {
	const canvasRef = React.useRef()
	const noiseRef = React.useRef()
	
	const width = window.innerWidth
	const height = window.innerHeight

	const r = props.type === "gold" ? 255 : 0
	const g = props.type === "gold" ? 215 : 235
	const b = props.type === "gold" ? 0 : 255

	const generateNoise = () => {
		noiseRef.current = document.createElement("canvas")
		let noise = noiseRef.current
		noise.height = height * 2
		noise.width = width * 2
		let noiseContext = noise.getContext('2d')

		let noiseData = noiseContext.createImageData(
			noise.width,
			noise.height
		)
	
	  	let buffer32 = new Uint32Array(noiseData.data.buffer)
	  	let len = buffer32.length - 1
	  	while (len--) {
	  	  	buffer32[len] = Math.random() < 0.5 ? 0 : -1 >> 0
	  	}
	  	
	  	for (let i = 0; i < noiseData.data.length; i += 4) {

	  		if(noiseData.data[i] == 255){
				noiseData.data[i + 0] = r
				noiseData.data[i + 1] = g
				noiseData.data[i + 2] = b
				noiseData.data[i + 3] = 255
			} else {
				noiseData.data[i + 0] = Math.round(r * darkPct)
				noiseData.data[i + 1] = Math.round(g * darkPct)
				noiseData.data[i + 2] = Math.round(b * darkPct)
				noiseData.data[i + 3] = 255
			}
		}
	
	  	noiseContext.putImageData(noiseData, 0, 0)
	}

	const moveNoise = () => {
		let canvas  = canvasRef.current
		if(canvas){
  			let context = canvas.getContext('2d')
  			let x = Math.random() * width
  			let y = Math.random() * height
  			context.clearRect(0, 0, width, height)
  			context.drawImage(noiseRef.current, -x, -y)
  		}
  		requestAnimationFrame(moveNoise)
	}

	React.useEffect(() => {
		generateNoise()
		moveNoise()
	},[])

	return <canvas style={s.canvas} ref={canvasRef}/>
}

export default Noise
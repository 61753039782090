import React from 'react'
import { View, StyleSheet, Dimensions } from 'react-native'
import { StationContext, UserContext, StatusContext, AudioContext, AdContext } from './Hooks'
import firebase from '../config/Firebase'
import { useHistory } from 'react-router-dom'

export default ({ children }) => {
	const history = useHistory()
	const { height, width } = Dimensions.get('window')

	const { stationID } = React.useContext(StationContext)
	const { user, userData, setUserData } = React.useContext(UserContext)
	const { setStatus } = React.useContext(StatusContext)
	const { isMuted, setIsMuted } = React.useContext(AudioContext)

	const [adLoading, setAdLoading] = React.useState(false)
	const [showAd, setShowAd] = React.useState(false)
	const [errorMsg, setErrorMsg] = React.useState({})

	const adVal = { showAd, setShowAd, errorMsg, setErrorMsg, adLoading, setAdLoading }

	const muteStoreRef = React.useRef(isMuted)

	const adStatusCallback = status => {
		console.log('Ad status: ', status)
		setAdLoading(false)
		
		let _userData
		switch(status) {
			case "ad-watched":
				setIsMuted(muteStoreRef.current)
				setErrorMsg({})
				setStatus('gold')
				_userData = Object.assign({}, userData)
				_userData.coins += 30
				setUserData(_userData)
				setShowAd(false)
				firebase.firestore().collection('users').doc(user.uid).set({
					lastAdTimestamp: Date.now(),
				},{merge:true})
				break
			case "fb-watched":
				setIsMuted(muteStoreRef.current)
				setErrorMsg({})
				setStatus('gold')
				_userData = Object.assign({}, userData)
				_userData.coins += 30
				setUserData(_userData)
				firebase.firestore().collection('users').doc(user.uid).set({
					lastAdTimestamp: Date.now(),
				},{merge:true})
				break
			case "ad-blocker":
				setShowAd(false)
				setIsMuted(muteStoreRef.current)
				setErrorMsg({title: "Ad Blocked", text: "Your ad blocker is preventing the video from displaying"})
				setStatus('error')
				if(stationID !== "earn"){ history.push('/earn')}
				break
			case "ads-unavailable":
				setShowAd(false)
				setIsMuted(muteStoreRef.current)
				setErrorMsg({title: "No Ads Available", text: "Try again later."})
				setStatus('error')
				if(stationID !== "earn"){ history.push('/earn')}
				break
			case "network-error":
				setShowAd(false)
				setIsMuted(muteStoreRef.current)
				setErrorMsg({title: "Connection Error", text:"Check your connection and try again."})
				setStatus('error')
				if(stationID !== "earn"){ history.push('/earn')}
				break
			case "sys-closing":
				break
			case "ad-started":
				setErrorMsg({})
				setIsMuted(true)
				break
			case "fb-started":
				setIsMuted(true)
				setErrorMsg({})
				break
			case "ad-interrupted":
				setShowAd(false)
				setIsMuted(muteStoreRef.current)
				setErrorMsg({title:"Ad Interrupted", text:"Did you hit the skip button?"})
				setStatus('error')
				//setIsMuted(muteStoreRef.current)
				if(stationID !== "earn"){ history.push('/earn')}
				break
			default:
				console.log("Default Error from Status: ", status)
				setErrorMsg({title:"System Error", text:"¯\\_(ツ)_/¯"})
				setStatus('error')
		}
	}

	React.useEffect(() => {
		if(showAd){
			console.log('Init ad')
			setErrorMsg({})
			setAdLoading(true)
			muteStoreRef.current = isMuted
			setIsMuted(true)
			var options = {                                                     
				zoneId: 3563,
				devId: 4588,
				gameId: 5637,
				userId: user.uid,
	 			muted: false,
	 			fallback: 1,
	 			btnDelay: 31,
	 			maxAdt: 31,
				adStatusCb: adStatusCallback
			};
			window.invokeApplixirVideoUnit(options)
		}
	},[showAd])

	const timeSinceAd = Date.now() - (userData.lastAdTimestamp || 0)
	const timeLeft = Math.ceil(Math.max(0,60 - timeSinceAd/1000))
	const stringTimeLeft = Math.floor(timeLeft/60) + ":" + (`0${timeLeft % 60}`).slice(-2)
	return (
		<AdContext.Provider value={adVal}>
			{children}
		</AdContext.Provider>
	)
}
//src/components/ProfileEdit.js

import React, { useState, useEffect, useContext } from 'react'
import { View, Text, TextInput, StyleSheet, ActivityIndicator, Dimensions, TouchableOpacity } from 'react-native'
import { UserContext } from '../../utils/Hooks'
import firebase from '../../config/Firebase'
import AvatarPill from '../CustomComponents/AvatarPill'
import Circle from '@material-ui/icons/Brightness1'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import DoneIcon from '@material-ui/icons/Done';
import { defaultColor } from '../../utils/GlobalStyles'
import DialogContent from '@material-ui/core/DialogContent';
import Avatar from '@material-ui/core/Avatar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const badwords = [
	"KKK", "ASS", "LSD", "FUK", "DIK", "SEX", "FUC", "TIT", 
	"666", "FAG", "GAY", "CUM", "JEW", "GOD", "DIE", "NIG", 
	"SUK", "SUC", "HEL", "T1T", "D1E", "N1G", "4SS", "D1K",
	"G0D", "G4Y", "F4G", "420"
]

const colorOptions = [
	'black',
	'brown',
	'orange',
	'green',
	'blue',
	'indigo',
	'violet'
]

const s = StyleSheet.create({
	container: {
		alignItems: 'center',
		justifyContent: 'center',
		flex: 1,
		width: '100%'
	},
	full: {
		width: '100%',
		height: '100%',
		maxWidth: 340,
	},
	colorOptionsContainer: {
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	btnContainer: {
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginTop: 20
	},
	textInputContainer: {
		width: '100%',
		borderColor:  'gray',
		borderRadius: 100,
		borderWidth: 1,
		paddingHorizontal: 6,
		height: 32,
	},
	usernameInput: {
		width: '100%',
		height: '100%',
		color: defaultColor,
		fontWeight: 'bold',
	},
	btn: {
		borderRadius: 100,
	},
	caption: {
		fontSize: 14,
		fontWeight: 'bold',
		color: 'white',
		marginTop: 20,
		marginBottom: 10,
	},
})

export default ({dismiss}) => {
	const { user, userData } = React.useContext(UserContext)

	const [color, setColor] = React.useState(userData.userColor || "gray")
	const [username, setUsername] = React.useState(userData.username || "Visitor")
	const [initials, setInitials] = React.useState(userData.initials || "000")
	const [isSaving, setIsSaving] = React.useState(false)

	const handleOnSave = () => {
		console.log("save clicked")
		setIsSaving(true)
		console.log("username: ", username)
		firebase.firestore().collection("users").doc(user.uid).set({
			username: username,
			userColor: color,
			initials: initials
		}, {merge:true})
		.finally(() => {
			setIsSaving(false)
		})
	}


	const handleExitPress = () => {
		firebase.auth().signOut()
		.then(() => window.location.reload())
	}

	React.useEffect(() => setColor(userData.userColor || "gray"),[userData.userColor])
	React.useEffect(() => setUsername(userData.username || "Visitor"),[userData.username])
	React.useEffect(() => setInitials(userData.initials || "000"),[userData.initials])
	
	React.useEffect(() => {
		if(badwords.indexOf(initials) > -1) {
			setInitials(initials.substring(0, initials.length - 1))
		}
	},[initials])

	const backDisabled = userData.username == null
	const saveDisabled = username == null || username == "" || username.length <= 3 || color == null || initials == null || initials.length < 3

	return (
		<View style={s.container}>
			<View style={[s.full, {padding: 20}]}>
				<View style={{width:'100%', paddingBottom: 30, alignItems: 'center',justifyContent: 'center'}}><Avatar src={userData.bitmojiUrl} /></View>
				<View style={{alignItems: 'center', width: '100%'}}>
					<AvatarPill size="medium" bitmojiUrl={userData.bitmojiUrl} name={username} color={color}/>
				</View>
				<Text style={s.caption}>Username (4 or more letters)</Text>
				<View style={s.textInputContainer}>			
					<TextInput
						maxLength={24}
						value={username} 
						style={s.usernameInput} 
						placeholder="You can always change it later"
						onChangeText={text=>{
							if(text.match(/^[a-zA-Z0-9_]*$/)){
								setUsername(text.trim())
							}
						}}
					/>
				</View>
				<Text style={s.caption}>User Initials (3 Letters)</Text>
				<View style={s.textInputContainer}>			
					<TextInput
						maxLength={3}
						value={initials} 
						style={s.usernameInput} 
						placeholder="For shorter name formats"
						onChangeText={text=>{
							if(text.match(/^[A-Z0-9\•]*$/i)){
								setInitials(text.toUpperCase().trim())
							}
						}}
					/>
				</View>
				<Text style={s.caption}>Choose a color</Text>
				<View style={s.colorOptionsContainer}>
					{colorOptions.map(_color => <ColorOption color={_color} key={_color} isSelected={color != null && color == _color} onPress={()=>setColor(_color)} />)}
				</View>
				<View style={[s.btn,{marginTop:20, backgroundColor: 'gray'}]}>
					<IconButton disabled={saveDisabled} onClick={handleExitPress} size="small">
						<ExitToAppIcon style={{color: 'black'}}/>
						<Text style={{fontWeight: 'bold'}}>Sign Out</Text>
					</IconButton>
					
				</View>
				<View style={[s.btn,{marginTop:20, backgroundColor: defaultColor}]}>
					{isSaving ?
						<View style={{width: '100%', height: 30, alignItems: 'center', justifyContent:'center'}}>
							<ActivityIndicator />
						</View>
						: <IconButton disabled={saveDisabled} onClick={handleOnSave} size="small">
							<DoneIcon style={{color: 'black'}}/>
							<Text style={{fontWeight: 'bold'}}>Save</Text>
						</IconButton>
					}
				</View>
			</View>
		</View>

	)
}

const ColorOption = ({color, isSelected, onPress}) => {
	const Icon = isSelected ? CheckCircleIcon : Circle
	return (
		<View style={{borderColor: 'white', borderWidth: 1, borderRadius: 100}}>
			<IconButton size="small" onClick={onPress}>
				<Icon style={{color: color}} />
			</IconButton>
		</View>
	)
}
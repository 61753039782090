import { StyleSheet } from 'react-native'

//GlobalStyles
export const defaultColor = '#00EBFF'

export default StyleSheet.create({
	fullContainer: {
		width: '100%',
		height: '100%',
	},
	center: {
		justifyContent: 'center',
		alignItems: 'center',
		textAlign: 'center'
	}
})
import React from 'react'
import Noise from './Noise'

const LoadingOverlay = ({ display }) => {
	return (
		<div style={{display: display ? 'inherit' : 'none'}}>
			<Noise />
		</div>
	)
}

export default LoadingOverlay
import React from 'react'
import { View, Text, TouchableHighlight, StyleSheet } from 'react-native'
//import FacebookIcon from '@material-ui/icons/Facebook';
//import MailIcon from '@material-ui/icons/Mail'

const s = StyleSheet.create({
	buttonStyle: {
		paddingHorizontal: 20,
		backgroundColor: 'rgba(0,0,0,0.5)',
		borderRadius: 230,
		height: 60,
		justifyContent: 'center',
		overflow: 'hidden'
	},
	contentContainer: {
		width: '100%',
		height: '100%',
		flexDirection: 'row',
		alignItems: 'center'
	},
	buttonText: {
		fontWeight: 'bold',
		color: 'white',
		//textAlign: 'center',
		fontSize: 24
	}
})

/*	facebook: <FacebookIcon style={{ color: '#4267B2' }} />,
	google: <MailIcon style={{color: 'red'}} />
}*/

const StyledButton = ({text, icon, onPress, color, width, style}) => {
	//const Icon = icons[icon]
	return (
		<TouchableHighlight underlayColor={color} onPress={onPress} style={[s.buttonStyle, {width:width, borderColor: color, borderWidth: color ? 3 : null}, style]}>
			<View style={s.contentContainer}>
				{/*icon && <Icon />*/}
				<Text style={s.buttonText}>{text.toUpperCase()}</Text>
			</View>
		</TouchableHighlight>
	)
}

export default StyledButton
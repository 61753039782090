import React, { useContext, useState, useEffect } from 'react'
import { View } from 'react-native'
import { StatusContext } from '../../utils/Hooks'

//var on = false

const StatusBar = () => {
	const { status, setStatus } = useContext(StatusContext)
	const [color,setColor] = useState('black')
	const onRef = React.useRef(false)

	const setFlash = (_color,numFlashes, interval) => {
		let i = 0
		onRef.current = false
		const timer = setInterval(() => {
			i++
			if(onRef.current) {
				onRef.current = false	
				setColor('black')
			} else {
				onRef.current = true
				setColor(_color)
			}
			if(i/(numFlashes*2) == 1){ 
				setStatus(null)
				clearInterval(timer) 
			}
		}, interval)
		return timer
	}

	//useEffect(() => setHasNotch(DeviceInfo.hasNotch()),[])

	useEffect(() => {
		let flash
		//console.log('Flash', status)
		switch(status){
			case 'engage':
				flash = setFlash('#00EBFF',3, 50)
				break
			case 'friend':
				flash = setFlash('#00EBFF',3,50)
				break
			case "click":
				flash = setFlash('#00EBFF',1,24)
				break
			case 'chat':
				flash = setFlash('#00EBFF', 2, 50)
				break
			case 'gold':
				flash = setFlash('gold',3, 50)
				break
			case 'tap':
				flash = setFlash('gold',1,24)
				break
			case 'error':
				flash = setFlash('red',2,50)
				break
		}
		return () => {
			if (flash){ clearInterval(flash) }
		}
	},[status])

	return (
		<View style={{
			height: 10, 
			width: '100%', 
			backgroundColor: color,
			shadowColor: color == 'black' ? null : color,
			shadowOpacity: 1,
			shadowRadius: 16
		}} />
	)
}

export default StatusBar
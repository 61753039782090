//src/components/ProfileEdit.js

import React from 'react'
import { View, Text, TextInput, StyleSheet, ActivityIndicator, Dimensions, TouchableOpacity } from 'react-native'
import Fab from '@material-ui/core/Fab';
import { defaultColor } from '../../utils/GlobalStyles'
import Modal from '@material-ui/core/Modal';
import DialogContent from '@material-ui/core/DialogContent';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import firebase from '../../config/Firebase'
import { StatusContext, UserContext, AudioContext } from '../../utils/Hooks'
import { AutoRotatingCarousel, Slide } from 'material-auto-rotating-carousel';
import earnCoinsImg1 from '../../assets/earnCoinsImg1.png'
import earnCoinsImg2 from '../../assets/earnCoinsImg2.png'
import earnCoinsImg3 from '../../assets/earnCoinsImg3.png'
import errorImg from '../../assets/errorImg.png'

const s = StyleSheet.create({
	container: {
		position:'absolute',
		height: '100%',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center'
	},
	captionContainer:{
		margin: 20
	},
	caption: {
		fontSize: 18,
		fontWeight: 'bold',
		textAlign: 'center'
	},
	errorMsg:{
		color: 'red',
		fontSize: 14,
	},
	errorTitle: {
		fontSize: 14,
		color: 'red',
		fontWeight: 'bold',
		marginBottom: 4
	}
})

const dataArray = [
	{
		text: "Earn coins by watching ads.",
		btnText: "NEXT"
	},
	{
		text: "Spend coins listening to music,\nwatching videos,\nand buying merch.",
		btnText: "NEXT"
	},
	{
		text: "Get your first coins",
		btnText: "GET COINS"
	}
]

export default ({display, dismiss}) => {
	const { setStatus } = React.useContext(StatusContext)
	const { user, userData, setUserData } = React.useContext(UserContext)
	const { setIsMuted } = React.useContext(AudioContext)
	const { width, height } = Dimensions.get('window')

	const [index, setIndex] = React.useState(0)
	const [adLoading, setAdLoading] = React.useState(false)
	const [errorMsg, setErrorMsg] = React.useState({})
	const [twoTap, setTwoTap] = React.useState(false)

	let handleOnPress = React.useCallback(() => {
		if(twoTap){
			setStatus('gold')
			let _userData = Object.assign({}, userData || {})
			_userData.completedTutorial = true
			setUserData({completedTutorial: true})
			firebase.firestore().collection('users').doc(user.uid).set({
				completedTutorial: true,
			}, {merge:true})
			setIsMuted(false)
			dismiss()
	   	} else {
	   		setTwoTap(true)
	   	}
	},[user,twoTap])

	return (
		<AutoRotatingCarousel 
			open={display} 
			label="START WITH 30 FREE COINS" 
			ButtonProps={{style:{backgroundColor: 'gold', borderRadius: 100, overflow: 'hidden'}}}
			onStart={handleOnPress} 
			containerStyle={{position: 'absolute', backgroundColor: 'black', width: '100%', height: '100%'}}
			mobile={width < 640}
		>
			<Slide
				media={<img src={earnCoinsImg1}/>}
				style={{backgroundColor: 'rgba(0,0,0,0.5)'}}
				mediaBackgroundStyle={{backgroundColor: 'transparent'}}
				title="Earn coins"
				subtitle="for watching ads"
			/>
			<Slide
				media={<img src={earnCoinsImg2}/>}
				style={{backgroundColor: 'rgba(0,0,0,0.5)'}}
				mediaBackgroundStyle={{backgroundColor: 'transparent'}}
				title="Spend coins"
				subtitle="on merch, music, and videos"
			/>
			<Slide
				media={<img src={earnCoinsImg3} />}
				style={{backgroundColor: 'rgba(0,0,0,0.5)'}}
				mediaBackgroundStyle={{backgroundColor: 'transparent'}}
				title="Let's get started"
				subtitle="get your first 30 coins free"
			/>
		</AutoRotatingCarousel>
	)
}
import React from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import AvatarPill from '../CustomComponents/AvatarPill'

const s = StyleSheet.create({
	container: {
		width:'100%',
		alignItems: 'flex-start',
		marginVertical: 4,
		marginLeft: 20,
		paddingRight: 30
	},
	innerContainer: {
		width: '85%',
		borderRadius: 25,
		padding: 10
	},
	message: {
		textAlign: 'left',
		textIndent: -20,
	},
	me: {
		backgroundColor: '#00EBFF'
	},
	them: {
		backgroundColor: 'rgba(0,0,0,0.5)'
	},
	messageText: {
		color: 'white',
		fontFamily: 'Roboto'
	}
})

const ChatMessage = ({ messageData, fromMe }) => {
	return (
		<View style={[s.container, {paddingLeft: fromMe ? 20 : 0}]}>
			<Text style={s.message}>
				<View style={{marginRight: 8, textIndent:0}}><AvatarPill name={messageData.username} bitmojiUrl={messageData.bitmojiUrl} color={messageData.userColor} /></View>
				<Text wrap style={[s.messageText,fromMe ? {color: 'lightgrey'}: null]}>{messageData.text}</Text>
			</Text>
		</View>
	)
}

export default ChatMessage
import React from 'react'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { View, Text, TouchableOpacity, StyleSheet, Animated, ScrollView } from 'react-native'
import { UserContext } from '../../utils/Hooks'
import { defaultColor } from '../../utils/GlobalStyles'
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const drawerWidth = '100%'

const s = StyleSheet.create({
	container: {
		flexDirection: 'row',
		flex:1,
		width: '100%',
	},
	sideDrawerContainer: {
		position: 'absolute',
		height: '100%',
		borderRightWidth: 10,
		borderColor: '#00EBFF',
		overflow: 'hidden'
	},	
	headerCtn: {
		alignItems: 'center',
		justifyContent: 'center',
		borderBottomWidth: 1,
		borderColor: 'rgba(255,255,255,0.2)',
		paddingBottom: 12,
		paddingBottom: 8
	},
	header: {
		color: '#00EBFF',
		fontWeight: 'bold'
	},
	chatContainer: {
		flex:1,
		height: '100%',
		width:'100%'
	},
	chatDetail: {
		width: '100%',
		height: '100%'
	},
	itemContainer: {
		height: 40,
		width:drawerWidth,
		borderBottomWidth: 1,
		borderColor: 'rgba(255,255,255,0.2)',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		paddingHorizontal: 10
	},
	header: {
		height: 40,
		width:'100%',
		backgroundColor: '#00EBFF',
		borderBottomWidth: 1,
		borderColor: 'rgba(255,255,255,0.2)',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		paddingHorizontal: 10,
		justifyContent: 'space-between',
	},
	itemText: {
		color: 'white',
		fontWeight: 'bold',
		fontSize: 14
	}
})

const testChatGroups = [
	{name: "Station Room", chatID: 'asdsfa', members: 1002, online: 100},
	{name: 'Target.com', chatID: 'asdfa',members: 34, online: 10},
	{name: 'Ethio Swag', chatID: 'ffwfe',members: 4, online: 1}
]
//chatGroups
export default ({ showProfile }) => {
	const { userData } = React.useContext(UserContext)
	const [drawerShouldOpen,setDrawerShouldOpen] = React.useState(true)
	const [drawerIsOpen, setDrawerIsOpen] = React.useState(true)
	const [chatGroups,setChatGroups] = React.useState(testChatGroups)
	const [chatID, setChatID] = React.useState(null)
	const [chatName, setChatName] = React.useState("")
	const anim = React.useRef(new Animated.Value(1)).current

	const handleGroupSelect = (_chatID,_chatName) => {
		console.log(_chatID, _chatName)
		setChatID(_chatID)
		setChatName(_chatName)
		setDrawerShouldOpen(false)
	}

	const handleJoinPress = () => {
		if(userData.snapID == null){
			showProfile()
		}
	}

	const handleNewRoomPress = () => {
		console.log("New Room Pressed")
		if(userData.snapID == null){
			showProfile()
		}
	}

	React.useEffect(() => {
		if(drawerIsOpen){
			setDrawerIsOpen(false)
		}
		console.log('Drawer should open: ', drawerShouldOpen)
		Animated.timing(anim, {
			toValue: drawerShouldOpen ? 1 : 0,
			duration: 250
		}).start(()=> {
			setDrawerIsOpen(drawerShouldOpen)
		})
	},[drawerShouldOpen])

	return (
		<View style={s.container}>
			<View style={[s.chatDetail, {display: drawerIsOpen ? "none" : "inherit"}]}>
				<TouchableOpacity onPress={()=> setDrawerShouldOpen(true)}>
					<View style={s.header}>
						<ChevronLeftIcon style={{color:"white"}}/>
						<Text numberOfLines={1} style={[s.itemText, {flex:1, textAlign: 'center', justifyContent: 'center'}]}>{chatName + " Chat"}</Text>
					</View>
				</TouchableOpacity>
			</View>
			<Animated.View style={[s.sideDrawerContainer, {width: anim.interpolate({inputRange: [0,1], outputRange:['0%','100%']})}]}>
				<ScrollView style={{width: '100%', height: '100%'}}>
					{testChatGroups.map(group => <SideDrawerItem key={group.chatID} group={group} isSelected={group.chatID == chatID} onPress={() => handleGroupSelect(group.chatID, group.name)}/>)}
					<TouchableOpacity onPress={handleJoinPress}>
						<View style={[s.itemContainer,{justifyContent: "center"}]}>
							<AddCircleOutlineIcon style={{color: 'white', marginRight: 4, fontSize: 20}} />
							<Text numberOfLines={1} style={s.itemText}>Join Domain Room</Text>
	        			</View>
					</TouchableOpacity>
					<TouchableOpacity onPress={handleNewRoomPress}>
						<View style={s.itemContainer}>
							<AddCircleOutlineIcon style={{color: 'white', marginRight: 4, fontSize: 20}} />
							<Text numberOfLines={1} style={s.itemText}>Open a New Room</Text>
						</View>
					</TouchableOpacity>
				</ScrollView>
			</Animated.View>
		</View>
	)
}

const SideDrawerItem = ({ group, isSelected, onPress }) => {
	return (
		<TouchableOpacity style={{backgroundColor: isSelected ? defaultColor : 'transparent'}} onPress={() => onPress(group.chatID)}>
			<View style={[s.itemContainer, {justifyContent: 'space-between'}]}>
				<Text  numberOfLines={1} style={s.itemText}>{group.name}</Text>
				<ChevronRightIcon style={{color:"white"}}/>
			</View>
		</TouchableOpacity>
	)
}
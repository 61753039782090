import React from 'react'
import { View, StyleSheet, Dimensions } from 'react-native'
import firebase from '../../config/Firebase'
import { VisualContext, StationContext } from '../../utils/Hooks'
import Video from '../CustomComponents/Video'



const s = StyleSheet.create({
	windowContainer: {
		position: 'absolute',
		width: '100%',
		overflow: 'hidden',
		backgroundColor: 'black',
		alignItems: 'center'
	},
	appContainer: {
		//position: 'absolute',
		height: '100%',
		//maxWidth: 480,
		width:'100%',
	},
	blurOverlay: {
		position: 'absolute',
		backgroundColor: 'black',
		opacity: 0.8,
		filter: 'blur(8px) contrast(0.4) brightness(0.4)'
	},
	backgroundVideo: {
		position: 'absolute',
		backgroundColor: 'black',
		minHeight: '100%',
		minWidth:'100%',
		width: 'auto',
		height: 'auto',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)'
	},
	loadingContainer: {
		position: 'absolute',
		height: '100%',
		width: '100%',
		backgroundColor: 'black',
	}
})

//Layout
const Layout = ({ location, children }) => {
	const { stationIsLoading, setStationIsLoading, stationData, showEarn } = React.useContext(StationContext)

	const [shouldShowBlur, setShouldShowBlur] = React.useState(true)
	const [showStatic, setShowStatic] = React.useState(false)
	const [visualID, setVisualID] = React.useState(null)
	const [visualList, setVisualList] = React.useState({})
	const [visualUrl, setVisualUrl] = React.useState()

	const { height, width } = Dimensions.get('window')
	const visualVal = { showStatic, setShowStatic, visualID, setVisualID }

	React.useEffect(()=>{
		let unsubscribeVisualListener = firebase.firestore().collection('visuals').onSnapshot( snap => {
			let docs = snap.empty ? [] : snap.docs
			let _visualList = docs.map(doc => doc.data)
			setVisualList(_visualList)
		})
		return unsubscribeVisualListener
	})

	React.useEffect(() => {
		//let visual = visualList[visualID] || {}
		//let _visualUrl = visual.url || 'https://storage.googleapis.com/sparkdj-livestream.appspot.com/visuals/qdEje4pnBlViJ5OymVZb.mp4'
		setVisualUrl(stationData.visualUrl)
	},[stationData.visualUrl])

	React.useEffect(() => {
		if(stationIsLoading){
			setShowStatic(true)
			setTimeout(() => {
				setShowStatic(false)
				setStationIsLoading(false)
			},400)
		}
	},[stationIsLoading])

	React.useEffect(() => {
		if (location == 'Auth'){
			setShouldShowBlur(true)
		} else {
			setShouldShowBlur(false)
		}
	},[location])

	return (
		<VisualContext.Provider value={visualVal}>
			 <View style={[s.windowContainer,{height: height}]}>
				{!showEarn && <Video src={visualUrl} style={s.backgroundVideo} height={height} muted autoPlay loop playsInline/>}
				<View style={s.appContainer}>
					{children}
				</View>
			</View>	
		</VisualContext.Provider>
	)
}

export default Layout
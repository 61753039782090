import React from 'react'
import { View, StyleSheet, Dimensions } from 'react-native'
import Auth from './Auth'
import { defaultColor } from '../../utils/GlobalStyles'

const s = StyleSheet.create({
	container: {
		position:'absolute',
		right: 0,
		top: 60,
		maxWidth: 340,
		width: '100%',
		alignItems: 'flex-end',
		justifyContent: 'flex-end'
	}
})

const badgeStyle = {
	backgroundColor: defaultColor
}

//Search
export default ({ display }) => {
	const { width } = Dimensions.get('window')

	const [show, setShow] = React.useState(false)

	React.useEffect(() => {
		if(display){ setShow(true)}
	},[display])

	return(
		<View style={[s.container, {bottom: width >= 640 ? 170 : 234, display: show ? 'inherit' : 'none'}]}>
			<Auth display={display} setShow={setShow} />
		</View>
	)
}
import React from 'react'
import { ScrollView, View, StyleSheet, Image } from 'react-native'
import { StationContext, UserContext } from '../../utils/Hooks'
import { databaseRef } from '../../config/Firebase'
import defaultAvatar from '../../assets/DefaultAvatar.png'
import Avatar from '@material-ui/core/Avatar';

const s = StyleSheet.create({
	container: {
		width: '100%',
		height: '100%',
		alignItems: 'flex-end',
		paddingBottom: 2,
	},
	viewerImg: {
		resizeMode: 'contain',
		height: 48,
		width: 48,
		marginHorizontal: 4,
		borderRadius: 100,
		overflow: 'hidden'
	}
})

export default () => {
	const { stationID } = React.useContext(StationContext)
	const { user } = React.useContext(UserContext)
	const [viewers,setViewers] = React.useState([])

	React.useEffect(() => {
		let ref = databaseRef.ref('viewers/' + stationID)
		ref.on('value', snapshot => {
			let data = snapshot.val()
			console.log("Data: ", data)
			if(data){
				let _viewers = Object.keys(data).map(key => {
					console.log('Inner Data')
					let body = {}
					body.bitmojiUrl = data[key].bitmojiUrl
					body.username = data[key].username
					body.userID = key
					return body
				})
				console.log('Viewers: ', _viewers)
				setViewers(_viewers)
			} else {

				setViewers([])
			}
		})
		return () => {
			if(ref){
				ref.off()
			}
		}
	}, [stationID])

	return (
		<ScrollView contentContainerStyle={s.container} prop={s.container} horizontal>
			{viewers.map(viewer => <View style={{marginHorizontal: 4}}><Avatar key={viewer.userID} src={viewer.bitmojiUrl} /></View>)}
		</ScrollView>
	)
}
import React from 'react'
import { StationContext, UserContext, StatusContext, AudioContext, AdContext } from '../../utils/Hooks'
import { View, ActivityIndicator, Text, StyleSheet, Dimensions } from 'react-native'
import Slider from '@material-ui/core/Slider';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import firebase from '../../config/Firebase' 
import { Howler } from 'howler'

const s = StyleSheet.create({
	container: {
		position: 'absolute',
		minHeight: '100%',
		minWidth:'100%',
		width: 'auto',
		height: 'auto',
		top:0,
		left:0,
		overflow: 'hidden',
		alignItems: 'center',
		justifyContent: 'center'
	},
	subContainer: {
		height: '100%',
		maxWidth: 340,
		paddingHorizontal: 20,
		paddingBottom: 100,
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center'
	},
	headline: {
		color: 'white',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		fontSize: 32,
		fontWeight: 'bold',
	},
	emoji: {
		fontSize: 36,
		marginTop:40,
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center'
	},
	earnBtn: {
		borderRadius: 25,
		backgroundColor: 'rgba(0,0,0,0.5)',
		marginTop: 4,
		padding: 6
	},
	text: {
		color: 'gold',
		textAlign:'center',
	},
	errorContainer: {
		width: '100%',
		alignItems: 'center',
		textAlign: 'center',
		justifyContent: 'center',
		height: 80
	},
	errorMsg:{
		color: 'red',
	},
	errorTitle: {
		color: 'red',
		fontWeight: 'bold',
		marginBottom: 4
	}
})

const WhiteNoiseStation = () => {
	const { height } = Dimensions.get('window')
	const { setStationID } = React.useContext(StationContext)
	const { user, userData, setUserData } = React.useContext(UserContext)
	const { isMuted, frequency, setFrequency } = React.useContext(AudioContext)
	const [noiseLoaded, setNoiseLoaded] = React.useState(false)
	const whiteNoiseRef = React.useRef()

	const handleChange = (event, newValue) => {
		setFrequency(newValue);
	};

	return (
		<View style={[s.container,{height:height}]}>
			<View style={s.subContainer}>
				<Slider style={{color:'white'}} value={frequency} onChange={handleChange} aria-labelledby="continuous-slider" />
			</View>
		</View>
    )
}

export default WhiteNoiseStation
import React from 'react'
import { View , Text, StyleSheet, Animated } from 'react-native'
import { StationContext } from '../../utils/Hooks'
import icons from '../../utils/Icons'
import { defaultColor } from '../../utils/GlobalStyles'
import { useHistory } from 'react-router-dom'

const s = StyleSheet.create({
	container: {
		position:'absolute',
		width: '100%',
		bottom: -80,
		height:102,
		overflow:'hidden'
	},
	innerContainer: {
		width: '100%',
		alignItems:'center',
	},
	stationItemContainer: {
		paddingHorizontal: 30,
		paddingVertical: 2,
		borderRadius:100,
		alignItems: 'center',
		backgroundColor: 'rgba(0,0,0,0.5)',
		borderWidth: 1,
		borderColor: 'white',
		marginBottom: 4,
		flexDirection: 'row',
		height: 30,
		alignItems:'center',
		justifyContent: 'center'
	},
	stationNameText: {
		color: 'white',
		fontWeight: 'bold',
		marginLeft: 4,
	}
})

const getFiveIndexArray = ({ index, arrayLength }) => {
	let indexArray = [index-2,index-1,index,index+1,index+2]
	let returnArray = []

	for(let i = 0; i < indexArray.length; i++){
		let _index = indexArray[i]
		if(_index == -2){ _index = arrayLength - 2 }
		if(_index == arrayLength + 1){ _index = 1 }
		if(_index == -1){ _index = arrayLength - 1 }
		if(_index == arrayLength){ _index = 0}
		returnArray.push(_index)
	}
	return returnArray
}
//StationFlipper
const StationFlipper = ({ stationList, display, isUp, setIsUp }) => {
	const history = useHistory()
	const { stationID, setStationID, setStationIsLoading, stationIsLoading } = React.useContext(StationContext)
	const [currentItems, setCurrentItems] = React.useState([])
	const [isChanging, setIsChanging] = React.useState(false)

	const anim = React.useRef(new Animated.Value(0)).current
	const displayAnim = React.useRef(new Animated.Value(0)).current

	const updateCurrentItems = _stationID => {
		//console.log("stationList (StationFlipper: ", stationList)
		let idList = stationList.map(station => station.stationID)
		let i = idList.indexOf(_stationID ||  stationID)
		if (i >= 0) { history.push('/'+stationList[i].slug) }
		let indexArray = getFiveIndexArray({index:i, arrayLength: stationList.length})
		let _currentItems = []
		indexArray.forEach(index => _currentItems.push(stationList[index]))
		setCurrentItems(_currentItems)
	}
	
	React.useEffect(()=> {
		if(!isChanging){ updateCurrentItems() }
	},[stationList, stationID])

	React.useEffect(() => {
		if(isUp === true || isUp === false){
			console.log("Current Items :", currentItems)
			//setIsChanging(true)
			setStationIsLoading(true)
			let _currentItems = currentItems.slice()
			let _stationID = _currentItems[isUp ? 1 : 3].stationID
			setStationID(_stationID)
			updateCurrentItems(_stationID)
			setIsUp(null)
			/*Animated.spring(anim, {toValue:isUp ? 1 : -1, friction: 6}).start(() => {
				updateCurrentItems(_stationID)
				setIsChanging(false)
				Animated.timing(anim, { toValue:0,duration:0 }).start()
				setIsUp(null)
				setStationIsLoading(false)
			})*/
		}
	},[isUp])

	React.useEffect(() => {
		Animated.timing(displayAnim, {toValue: display ? 1 : 0, duration: 250}).start()
	},[display])

	return (
		<Animated.View style={[s.container,{opacity: displayAnim}]}>
			<Animated.View style={[s.innerContainer,{transform:[{translateY:anim.interpolate({inputRange:[-1,0,1],outputRange:[-68,-34,0]})}]}]}>
				<StationItem item={currentItems[0]}/>
				<StationItem item={currentItems[1]} isCurrent={isChanging && isUp}/>
				<StationItem item={currentItems[2]} isCurrent={!isChanging}/>
				<StationItem item={currentItems[3]} isCurrent={isChanging && !isUp}/>
				<StationItem item={currentItems[4]}/>
			</Animated.View>
		</Animated.View>
	)
}

const StationItem = ({item, isCurrent}) => {
	const Icon = item && item.icon ? icons[item.icon] : View
	const name = item && item.name || null
	return (
		<View style={[s.stationItemContainer, {opacity: isCurrent ? 1 : 0.4, backgroundColor: isCurrent ? defaultColor : 'black'}]}>
			<Icon style={{color: isCurrent ? 'black' : 'white'}}/>
			<Text nowrap style={[s.stationNameText,{color: isCurrent ? 'black' : 'white'}]}>{(name || "").toUpperCase()}</Text>
		</View>
	)
}

export default StationFlipper
import React from 'react'
import { View, Text, StyleSheet, Animated, TextInput,FlatList, TouchableOpacity,ScrollView } from 'react-native'
import { StationContext, UserContext } from '../../utils/Hooks'
import ChatMessage from './ChatMessage'
import ChatGroups from './ChatGroups'
import IconButton from '@material-ui/core/IconButton'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import GroupIcon from '@material-ui/icons/Group'
import ForumIcon from '@material-ui/icons/Forum'
import icons from '../../utils/Icons'
import { defaultColor } from '../../utils/GlobalStyles'
import firebase from '../../config/Firebase'
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const s = StyleSheet.create({
	container: {
		height: '100%',
		width: '100%',
		marginRight: 20, 
	},
	blur: {
		backgroundColor: 'rgba(0,0,0,0.5)',
		borderRadius: 25,
	},
	inputContainer: {
		width: '100%',
		flexDirection: 'row',
		paddingLeft: 20,
		paddingRight: 10,
		marginVertical: 10
	},
	headerCtn: {
		alignItems: 'center',
		justifyContent: 'center',
		borderBottomWidth: 1,
		borderColor: 'rgba(255,255,255,0.2)',
		paddingBottom: 12,
		paddingBottom: 8
	},
	header: {
		color: '#00EBFF',
		fontWeight: 'bold'
	},
	chatTabBar: {
		flexDirection: 'row',
		height: 50,
		borderTopWidth: 1,
		borderColor: 'rgba(255,255,255,0.2)',
		paddingHorizontal: 4,
		marginVertical: 8
		//marginBottom: 4,
	},
	chatTab: {
		flex:1,
		alignItems: 'center',
		justifyContent: 'center'
	},
	chatTabText: {
		fontWeight: 'bold',
	},
	chatIconContainer: {
		flex:1,
		alignItems: 'center',
		justifyContent: 'center'
	},
	textInputContainer: {
		flex: 1,
		borderColor:  '#00EBFF',
		borderRadius: 100,
		borderWidth: 1,
		paddingHorizontal: 6
	},
	sendBtn: {
		borderRadius: 100,
		//borderWidth: 1,
		marginLeft: 10
	},
	textInput: {
		width: '100%',
		height: '100%',
		color: 'white',
	}
})

const chatTabs = {
	station: 0,
	groups: 1,
	topics: 2
}

const tabName ={
	0: "Station Chat",
	1: "Viewing Rooms"
}

//ChatContainer
export default ({ display, uid, setShow, stationChats, showProfile, engageList, groupChats, groupID, setGroupID }) => {
	const { stationData, stationID } = React.useContext(StationContext)
	const { user, userData} = React.useContext(UserContext)

	const scrollViewRef = React.useRef()
	
	const [chatTab, setChatTab] = React.useState(chatTabs.station)
	const [value, onChangeText] = React.useState("")
	const [isSending, setIsSending] = React.useState(false)
	const anim = React.useRef( new Animated.Value(0)).current
	const chatAnim = React.useRef( new Animated.Value(0)).current

	const handleOnSubmit = () => {
		if(!isSending && value && value.length > 0){
			console.log("sending")
			setIsSending(true)
			let chatRef = firebase.database().ref(`chats/${stationID}`).push()
			let body = {
				messageID: chatRef.key,
				username: userData.username,
				userColor: userData.userColor,
				stationID: stationID,
				text: value,
				timestamp: Date.now(),
				userID: user.uid
			}
			if (userData.bitmojiUrl){ body.bitmojiUrl = userData.bitmojiUrl }
			chatRef.set(body)
			.then(() => {
				onChangeText('')
			}).catch(err => {
				console.log(err)
			}).finally(() => {
				setIsSending(false)
			})
		}
	}

	const handleTabChange = (e, i) => {
		setChatTab(i)
	}

	React.useEffect(() => {
		if(display == true){ setShow(true)}
		Animated.timing(anim, {
			toValue: display ? 0 : 1,
			duration: 100
		}).start(() => {
			if(display == false){ setShow(false)}
		})
	},[display])

	React.useEffect(() => {
		Animated.timing(chatAnim,{
			toValue: chatTab == chatTabs.station ? 0 : 1,
			duration: 100
		}).start()
	},[chatTab])

	const StationIcon = stationData.icon ? icons[stationData.icon] : View
	return (
		<Animated.View style={[
			s.container,
			{transform: [{translateX: anim.interpolate({inputRange: [0,1], outputRange:['0%','100%']})}]}
		]}>
			{/*<View style={[s.container, s.blur]} />*/}
			<View style={[s.container, s.blur, {padding:10}]}>
				<View style={s.headerCtn}><Text style={s.header}>{tabName[chatTab]}</Text></View>
				<ScrollView 
					ref={ref => scrollViewRef.current = ref}
					indicatorStyle="black" 
					style={{flex:1,display: chatTab == chatTabs.station ? 'flex':'none'}}
					onContentSizeChange={() => scrollViewRef.current && scrollViewRef.current.scrollToEnd({animated: true})}
				>
					{stationChats.map((item,i) => <ChatMessage key={i} uid={user.uid} messageData={item} fromMe={item.userID == user.uid}/>)}
				</ScrollView>
				<View style={{flex:1, display: chatTab == chatTabs.groups ? 'flex':'none'}}><ChatGroups showProfile={showProfile} groupChats={groupChats} setGroupID={setGroupID}/></View>
				<View style={[s.inputContainer,{display: display !== 0 ? 'flex' : 'none'}]}>
					<View style={s.textInputContainer}>
						<TextInput 
							value={value}
							style={s.textInput} 
							blurOnSubmit={false}
							onChangeText={text => onChangeText(text)} 
							placeholder="What do you think?"
							onSubmitEditing={handleOnSubmit}
						/>
					</View>
					<View style={[s.sendBtn,{backgroundColor: defaultColor}]}><IconButton onClick={handleOnSubmit} size="small" ><ArrowUpwardIcon style={{color: 'black'}}/></IconButton></View>
				</View>
				<View style={s.chatTabBar}>
					<TouchableOpacity style={s.chatTab} onPress={()=>setChatTab(chatTabs.station)} style={[s.chatTab,{borderRightWidth: 0,borderColor: 'rgba(255,255,255,0.2)'}]}>
						<View style={s.chatIconContainer}><StationIcon style={{fontSize: 18, paddingTop: 2, color: chatTab == chatTabs.station ? defaultColor : 'gray'}}/></View>
						<Text style={{fontWeight:'bold',color: chatTab == chatTabs.station ? defaultColor : 'gray'}}>Station Chat</Text>
					</TouchableOpacity>
					{/*<TouchableOpacity onPress={()=>setChatTab(chatTabs.groups)}  style={s.chatTab}>
						<View style={s.chatIconContainer}><MeetingRoomIcon style={{color: chatTab == chatTabs.groups ? defaultColor : 'gray'}}/></View>
						<Text style={{fontWeight:'bold',color: chatTab == chatTabs.groups ? defaultColor : 'gray'}}>Viewing Rooms</Text>
					</TouchableOpacity>*/}
				</View>
				{/*<Tabs
			        value={value}
			        onChange={handleTabChange}
			        variant="fullWidth"
			        indicatorColor="default"
			        textColor="default"
			        aria-label="icon label tabs example"
			    >
			    	<Tab icon={<StationIcon />} label="Station Chat" />
			        <Tab icon={<MeetingRoomIcon />} label="Chat Rooms" />
			    </Tabs>*/}
			</View>
		</Animated.View>
	)
}
import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyDBSwW7m1E-hU5cHexpidjkg7xgS8xEtG4",
  authDomain: "sparkdj-livestream.firebaseapp.com",
  databaseURL: "https://sparkdj-livestream.firebaseio.com",
  projectId: "sparkdj-livestream",
  storageBucket: "sparkdj-livestream.appspot.com",
  messagingSenderId: "1017400084568",
  appId: "1:1017400084568:web:77b394c7ca89a9b0c3622d",
  measurementId: "G-M8C46DNR11"
};

firebase.initializeApp(firebaseConfig)
const settings = {timestampsInSnapshots: true};
firebase.firestore().settings(settings)


const musicListen = firebase.functions().httpsCallable('musicListen')
const storageRef = firebase.storage().ref()
const firestoreRef = firebase.firestore()
const databaseRef = firebase.database()
const authRef = firebase.auth()

const getSnapToken = firebase.functions().httpsCallable('getSnapToken')

export {
	musicListen,
	storageRef,
	firestoreRef,
	databaseRef,
  authRef,
  getSnapToken
}

export default firebase
import React from 'react'
import { View, StyleSheet, Dimensions } from 'react-native'
import { StationContext } from '../../utils/Hooks'
import { defaultColor } from '../../utils/GlobalStyles'
import { UserContext } from '../../utils/Hooks'
import firebase from '../../config/Firebase'
import ChatContainer from './ChatContainer'

const s = StyleSheet.create({
	container: {
		position:'absolute',
		right: 0,
		top: 60,
		maxWidth: 340,
		width: '100%',
		alignItems: 'flex-end',
		justifyContent: 'flex-end'
	}
})

const badgeStyle = {
	backgroundColor: defaultColor
}

//Chat
export default ({ engageList, display, showProfile }) => {
	const { user } = React.useContext(UserContext)
	const { height, width } = Dimensions.get('window')

	const { stationIsLoading, stationID, joinTimestamp } = React.useContext(StationContext)

	const [show, setShow] = React.useState(false)
	const [hasNewChats, setHasNewChats] = React.useState(true)
	const [stationChats, setStationChats] = React.useState([])
	const [groupChats, setGroupChats] = React.useState([])
	const [groupID, setGroupID] = React.useState(null)

	React.useEffect(() => {
		if((stationChats.length > 0 || groupChats.length > 0) && !display){
			setHasNewChats(true)
		}
	},[stationChats,groupChats])

	React.useEffect(() => {
		setHasNewChats(false)
		if(display){ setShow(true)}
	},[display])

	React.useEffect(() => {
		
		let engageChat = []
		//convert engagements to chats
		for (let i = 0; i < engageList.length; i++){
			let engagement = engageList[i]
			if(engagement.type === "thumbs" && engagement.timestamp >= joinTimestamp && engagement.stationID == stationID){
				engageChat.push({
					messageID: engagement.engageID,
					username: engagement.username,
					userColor: engagement.userColor,
					stationID: stationID,
					text: `just ${engagement.payload.selectedUp == true ? "liked" : "disliked" } ${engagement.payload.title} by ${engagement.payload.artist}`,
					timestamp: engagement.timestamp,
					userID: engagement.userID
				})
			}
		}

		console.log("Engage Chat: ", engageChat)
		const stationChatRef = firebase.database().ref(`chats/${stationID}`).orderByChild('timestamp').limitToFirst(100).startAt(joinTimestamp)
		stationChatRef.on('value', snap => {
			let data = snap.val() || {}
			//console.log("Station chat data: ", data)
			let _stationChats = Object.values(data) || []
			_stationChats = _stationChats.concat(engageChat)
			_stationChats.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1)
			//console.log("Station Chats: ", _stationChats)
			setStationChats(_stationChats)			
		})

		const groupChatRef = firebase.database().ref(`chats/${groupID}`).orderByChild('timestamp').limitToFirst(100).startAt(joinTimestamp)
		groupChatRef.on('value', snap => {
			let data = snap.val() || {}
			
			let _groupChats = Object.values(data) || []
			_groupChats = _groupChats.concat(engageChat)
			_groupChats.sort((a, b) => (a.timestamp > b.timestamp) ? 1 : -1)
			setGroupChats(_groupChats)
			
		})

		return () => {
			setStationChats([])
			groupChatRef.off()
			stationChatRef.off()
		}
	},[engageList, stationID, groupID])

	return(
		<View style={[s.container,{bottom: width >= 640 ? 170 : 234, display: stationIsLoading || !show ? 'none' : 'flex'}]}>
			<ChatContainer setShow={setShow} showProfile={showProfile} stationChats={stationChats} engageList={engageList} uid={user.uid} display={display} groupID={groupID} setGroupID={setGroupID}/>
		</View>
	)
}
import React from 'react'
import { View, Text, StyleSheet } from 'react-native'
import g from '../utils/GlobalStyles'

//BusinessContent
export default () => {
	return (
		<View style={[g.fullContainer, g.center, {backgroundColor: 'yellow'}]}>
			<Text>Business Content</Text>
		</View>
	)
}
import React from 'react'
import { StationContext, UserContext, StatusContext, AudioContext, AdContext } from '../../utils/Hooks'
import { View, ActivityIndicator, Text, StyleSheet, Dimensions } from 'react-native'
import Fab from '@material-ui/core/Fab';
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo';
import firebase from '../../config/Firebase'
import Noise from '../Navigation/Noise'

const s = StyleSheet.create({
	container: {
		position: 'absolute',
		backgroundColor: '#ffdd1f',
		minHeight: '100%',
		minWidth:'100%',
		width: 'auto',
		height: 'auto',
		top:0,
		left:0,
		overflow: 'hidden',
		alignItems: 'center',
		justifyContent: 'center'
	},
	subContainer: {
		position: 'absolute',
		height: '75%',
		width: '75%',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'black',
		zIndex: 100000,
	},
	headline: {
		color: 'gold',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		fontSize: 32,
		fontWeight: 'bold',
	},
	emoji: {
		fontSize: 36,
		marginTop:40,
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center'
	},
	earnBtn: {
		borderRadius: 25,
		backgroundColor: 'rgba(0,0,0,0.5)',
		marginTop: 4,
		padding: 6
	},
	text: {
		color: 'gold',
		textAlign:'center',
	},
	errorContainer: {
		width: '100%',
		alignItems: 'center',
		textAlign: 'center',
		justifyContent: 'center',
		height: 80
	},
	errorMsg:{
		color: 'red',
	},
	errorTitle: {
		color: 'red',
		fontWeight: 'bold',
		marginBottom: 4
	}
})

const EarnStation = props  => {
	const { height } = Dimensions.get('window')
	//const { setStationID } = React.useContext(StationContext)
	const { user, userData, setUserData } = React.useContext(UserContext)
	const [isEarning, setIsEarning] = React.useState(false)
	const { showAd, setShowAd, errorMsg, adLoading } = React.useContext(AdContext)

	const handleOnPress = () => setShowAd(true)

	const startEarning = () => {

	}

	const stopEarning = () => {

	}

	React.useState(() => {
		if(props.display){
			setIsEarning(true)
		} else {
			setIsEarning(false)
		}
	},[props.display])

	const timeSinceAd = Date.now() - (userData.lastAdTimestamp || 0)
	const timeLeft = Math.ceil(Math.max(0,60 - timeSinceAd/1000))
	const stringTimeLeft = Math.floor(timeLeft/60) + ":" + (`0${timeLeft % 60}`).slice(-2)
	return (
		<View style={[s.container,{height:height, display: props.display ? "inherit" : 'none'}]}>
			<Noise type="gold" />
			<View style={s.subContainer}>
				<Text style={s.headline}>Earning 1 ⵙ coin per second</Text>
				<Text style={{marginVertical: 20, textAlign:'center', fontWeight: 'bold', color: 'white'}}>No Ads Available</Text>
				<View />
			</View>
		</View>
    )
}

export default EarnStation
import React from 'react'
import { View, StyleSheet, Dimensions } from 'react-native'
import SearchContainer from './SearchContainer'
import { defaultColor } from '../../utils/GlobalStyles'
import { StationContext } from '../../utils/Hooks'

const s = StyleSheet.create({
	container: {
		position:'absolute',
		right: 0,
		top: 60,
		maxWidth: 340,
		width: '100%',
		alignItems: 'flex-end',
		justifyContent: 'flex-end'
	}
})

const badgeStyle = {
	backgroundColor: defaultColor
}

//Search
export default ({ stationList, display, dismiss, present }) => {
	const { stationID } = React.useContext(StationContext)
	const { width } = Dimensions.get('window')

	const [show, setShow] = React.useState(false)

	React.useEffect(() => {
		if(display){ setShow(true)}
	},[display])

	return(
		<View style={[s.container, {bottom: width >= 640 ? 170 : 234, display: show ? 'inherit' : 'none'}]}>
			<SearchContainer display={display} setShow={setShow} stationList={stationList}/>
		</View>
	)
}
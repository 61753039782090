import React from 'react'
import { Animated, View, Text, StyleSheet, Dimensions } from 'react-native'
import icons from '../../utils/Icons'
import { StationContext } from '../../utils/Hooks'

const s = StyleSheet.create({
	container: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%'
	},
	stationNameContainer:{
		position: 'absolute',
		top: 0,
		left: 0,
		paddingTop: 50,
		paddingLeft: 30
	},
	titleContainer:{
		textAlign: 'left',
		alignItems: 'center',
		flexDirection: 'row'
	},
	titleText:{
		//fontFamily: 'Roboto',
		fontWeight: 'bold',
		color: 'white',
		fontStyle: 'italic'
	},
	descriptionText:{
		color: 'white',
		textAlign: 'left'
	},
	tagText: {
		fontWeight: 'bold',
		color: 'white'
	},
	streamTextContainer: {
		position: 'absolute',
		top: 20,
		left: 20

	},
	streamText: {
		fontWeight: 'bold',
		color: 'gray',
		//fontFamily: 'Roboto',
		fontSize: 16,
	},
})
//StationTitle
export default ({stationData}) => {
	const { stationIsLoading } = React.useContext(StationContext)
	const { height, width } = Dimensions.get('window')

	const anim = React.useRef(new Animated.Value(0)).current
	const [isLive, setIsLive] = React.useState(false)

	const stationID = stationData.stationID
	const streamText = stationData.stationID == "whitenoise" ? "" : "BROADCAST"
	const Icon = stationData && stationData.icon ? icons[stationData.icon] : View
	
	React.useEffect(() => {
		if(!stationIsLoading){
			Animated.sequence([
				Animated.timing(anim, {toValue:0, duration:0}),
				Animated.timing(anim,{toValue:0.5, duration:250 }),
				Animated.timing(anim,{toValue:1, duration: 250, delay: 6000}),
			]).start()
		}
	},[stationID, stationIsLoading, anim])

	return (
		<View style={[s.container,{display: stationIsLoading ? 'none' : 'block'}]}>
			<Animated.View style={[s.streamTextContainer,{opacity:anim.interpolate({
				inputRange: [0,0.5,1],
				outputRange: [1,1,0.2]
			})}]}>
				<Text style={[s.streamText]}>{streamText}</Text>
			</Animated.View>
			<Animated.View 
				style={[
					s.stationNameContainer, 
					{
						opacity:anim.interpolate({
							inputRange: [0,0.5,1],
							outputRange: [0,1,0]
						}), 
						transform:[{
							translateX: anim.interpolate({
								inputRange: [0,0.5,1],
								outputRange: [0,10,20]
							})
						}]
					}
				]}
			>
				<View style={s.titleContainer}>
					<Icon style={{color: 'white', fontSize: width >= 640 ? 54 : 30, marginRight: 8}} />
					<Text style={[s.titleText,{fontSize: width >= 640 ? 60 : 34}]}>{(stationData.name || '').toUpperCase()}</Text>
				</View>
				<View style={{paddingLeft: 80, paddingRight: 20}}>
					<Text style={s.descriptionText}>{stationData.description}</Text>
				</View>
			</Animated.View>
		</View>
	)
}
import React from 'react'
import { View, Text, StyleSheet, Dimensions } from 'react-native'
import { StationContext, AudioContext, UserContext } from '../utils/Hooks'
import firebase from '../config/Firebase'
import g from '../utils/GlobalStyles'
import BottomBar from '../components/BottomBar'
import TopBar from '../components/TopBar'
import Chat from '../components/Chat'
import Search from  '../components/Search'
import Stations from '../components/Stations'
import Music from '../utils/Music'
import EarnStation from '../components/Stations/EarnStation'
import Fab from '@material-ui/core/Fab'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import Badge from '@material-ui/core/Badge'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff'
import WhiteNoiseStation from '../components/Stations/WhiteNoiseStation'
import ViewerDisplay from '../components/BottomBar/ViewerDisplay'
import SpeedDial from '@material-ui/lab/SpeedDial'
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon'
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'
import PersonIcon from '@material-ui/icons/Person'
import SettingsIcon from '@material-ui/icons/Settings'
import { makeStyles } from '@material-ui/core/styles'
import Profile from '../components/Profile'
import Settings from '../components/Settings'
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

var isMobile = false
if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
 // some code..
 isMobile = true
}

const s = StyleSheet.create({
	middleContainer: {
		flex: 1,
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'center'
	},
	viewerContainer: {
		flex:1,
		justifyContent: 'center',
		height: 60,
		paddingRight: 20
	}
})

const useStyles = makeStyles({
  btn: {
  	color: '#00EBFF'
  }
});

const dial = {
	chat: 0,
	search: 1,
	settings: 2,
	profile: 3
}
//ConsumerContent
const ConsumerContent = () => {
	const classes = useStyles()
	const { stationData, setStationData, stationID, setStationID, joinTimestamp, showEarn } = React.useContext(StationContext)
	const { user, userData } = React.useContext(UserContext)
	const { height, width } = Dimensions.get('window')

	const [currDial, setDial] = React.useState((width > 640 && !isMobile) ? dial.chat : null)
	const [engageList, setEngageList] = React.useState([])
	const [contentList, setContentList] = React.useState([])
	const [stationList, setStationList] = React.useState([])
	const [currentContent, setCurrentContent] = React.useState({})
	const [progress, setProgress] = React.useState(0)
	const [tic, setTic] = React.useState(false)
	const [isMuted, setIsMuted] = React.useState(true)
	const [masterVol, setMasterVol] = React.useState(0.75)
	
	const [frequency, setFrequency] = React.useState(25)
	const [openSpeedDial, setOpenSpeedDial] = React.useState(false)

	const audioVal = { isMuted, setIsMuted, masterVol, setMasterVol, frequency, setFrequency }

	const refreshContent = () => setTic(!tic)

	const handleChatPress  = () => {
		setDial(dial.chat)
		//setShowChat(!showChat)
		//setOpenSpeedDial(false)
	}

	const handleSearchPress = () => {
		//setShowSearch(!showSearch)
		setDial(dial.search)
		//setOpenSpeedDial(false)
	}

	const handleProfilePress = () => {
		setDial(dial.profile)
		//setShowProfile(!showProfile)
		//setOpenSpeedDial(false)
	}

	const handleSettingsPress = () => {
		//setShowSettings(!showSettings)
		setDial(dial.settings)
	}

	React.useEffect(() => {
		//console.log('checking for stations')
		const stationListener = firebase.firestore().collection('stations').where('listed','==',true)
			.onSnapshot(snap => {
				setStationList(snap.empty ? [] : snap.docs.map(doc => doc.data()))
				//console.log("stationList (ConsumerContent: ", snap.docs.map(doc => doc.data()))
		})
		return stationListener
	},[])

	React.useEffect(() => {
		const engageListener = firebase.firestore().collection('engage')
			.where("stationID", "==", stationID || "")
			.where("timestamp", ">=", joinTimestamp)
			//.where("payload.scheduledContentID", "==", currentContent.scheduledContentID || "")
			.onSnapshot(snap => {
				let data = snap.empty ? [] : snap.docs.map(doc => doc.data())
				let mergeList = engageList.concat(data)
				let engageIDArray = []
				let _engageList = []

				mergeList.forEach(item => {
					if(engageIDArray.indexOf(item.engageID) == -1) {
						engageIDArray.push(item.engageID)
						_engageList.push(item)
					}
				})
				setEngageList(_engageList)
				//console.log("Engage List: ", engageList)
			})
		return () => {
			engageListener()
		}
	},[currentContent, stationID])

	React.useEffect(() => {
		let _stationData = stationList.filter(station => station.stationID === stationID)[0]
		setStationData(_stationData || {})
	},[stationID, stationList])
	
	React.useEffect(() => {
		let now = Date.now()
		const contentListener = firebase.firestore().collection('scheduledContent')
			.where('stationID',"==", stationID || "")
			.where('endTimestamp', ">=", now)
			.orderBy("endTimestamp")
			.limit(3)
			.onSnapshot(snap => {
				setContentList(snap.empty ? [] : snap.docs.map(doc => doc.data()))
				console.log("contentList (ConsumerContent): ", snap.docs.map(doc => doc.data()))
			})
		return contentListener
	},[stationID, tic])

	/*React.useEffect(() => {
		if(width <= 640){
			setShowSearch(false)
			setShowChat(false)
		}
	},[stationID, userData.completedTutorial])*/


	const handleOnClick = (e,i) => {

		//if(e.target.className.baseVal && e.target.className.baseVal.indexOf("MuiSpeedDialIcon-icon") >= 0){
			setOpenSpeedDial(!openSpeedDial)
		//} else {

		//}
	}

	const handleOnClose = () => {
		setOpenSpeedDial(false)
	}

	return (
		<AudioContext.Provider value={audioVal}>
			<View style={g.fullContainer}>
				<Music contentList={contentList} progress={progress} setProgress={setProgress} refreshContent={refreshContent} setCurrentContent={setCurrentContent}/>
				<Stations stationList={stationList} stationID={stationID} setStationID={setStationID} />
				{/*showEarn && <EarnStation />*/}	
				{stationID == "whitenoise" && <WhiteNoiseStation />}
				<View style={{flexDirection: 'row', alignItems: 'flex-end', padding:20, zIndex: openSpeedDial ? 100 : 0}}>
					<View style={s.viewerContainer}>
						<ViewerDisplay />
					</View>
					<SpeedDial
						ariaLabel="Speedial"
						FabProps={{color: 'default', zIndex: openSpeedDial}}
						icon={<SpeedDialIcon />}
						open={openSpeedDial}
						onOpen={handleOnClick}
						onClose={handleOnClose}
						direction="up"
					>
						<SpeedDialAction key="Close" icon={<ChevronRightIcon />} tooltipTitle="Close" tooltipOpen={false} onClick={()=>setDial(null)}/>
						<SpeedDialAction key="Chat" icon={<SpeakerNotesIcon />} tooltipTitle="Chat" tooltipOpen={false} onClick={handleChatPress}/>
						<SpeedDialAction key="Search" icon={<SearchIcon />} tooltipTitle="Search" tooltipOpen={false} onClick={handleSearchPress}/>
						<SpeedDialAction key="Settings" icon={<SettingsIcon />} tooltipTitle="Settings" tooltipOpen={false} onClick={handleSettingsPress}/>
						<SpeedDialAction key="Profile" icon={<PersonIcon />} tooltipTitle="Profile" tooltipOpen={false} onClick={handleProfilePress}/>
					</SpeedDial>
				</View>
				<Search stationList={stationList} display={currDial == dial.search} />
				<Chat showProfile={() => setDial(dial.profile)} engageList={engageList} currentContent={currentContent} display={currDial == dial.chat} />
				<Profile display={currDial == dial.profile} />
				<Settings display={currDial == dial.settings} />
				<BottomBar engageList={engageList} currentContent={currentContent} networkArtUrl="" progress={progress}/>
				{/*<EarnStation display={showEarn} />*/}
				{/*<TopBar currentContent={currentContent} showProfile={showProfile} setShowProfile={setShowProfile}/>*/}
			</View>
		</AudioContext.Provider>
	)
}

export default ConsumerContent
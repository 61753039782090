import React from 'react'
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native'
import SettingsIcon from '@material-ui/icons/Settings'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/Button'
import firebase, { musicListen } from '../../config/Firebase'
import { UserContext, StationContext, AudioContext, AdContext } from '../../utils/Hooks'
import UserInfo from './UserInfo'
import Tutorial from './Tutorial'
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from 'react-router-dom'
import RefreshIcon from '@material-ui/icons/Refresh'
import { makeStyles } from '@material-ui/core/styles'

const s = StyleSheet.create({
	container: {
		position:'absolute',
		width: '100%',
		flex:1,
		flexDirection: 'row',
		justifyContent: 'flex-end',
		alignItems: 'center',
		paddingRight: 10,
		height: 64
	},
	currency: {
		fontWeight: 'bold',
		color: 'gold',
		textShadow: "2px 2px black",
		fontSize: 18,
		marginRight: 10
	}
})

const menuItem = {
	businesses: 0,
	contactUs: 1,
	submitMusic: 2,
	investors:3,
	logout: 4,
	privacy: 5,
	curator:6
}

export default ({ currentContent, showProfile, setShowProfile }) => {
	const history = useHistory()

	const { userData, userDataInit, setUserData } = React.useContext(UserContext)
	const { showAd, setShowAd } = React.useContext(AdContext)
	const { stationID } = React.useContext(StationContext)
	const { setIsMuted } = React.useContext(AudioContext)

	const [anchorEl, setAnchorEl] = React.useState(null)
	const [showTutorial, setShowTutorial] = React.useState(false)
	const [timeTarget, setTimeTarget] = React.useState(Date.now() + 30000)
	const [tic, setTic] = React.useState(false)
	const [progress, setProgress] = React.useState(0)
	const [inGrace, setInGrace] = React.useState(true)

	const name = userData.username || "Visitor"
	const color = userData.userColor || 'gray'
	const bitmojiUrl = userData.bitmojiUrl

	const handleSettingsPress = evt => {
		setAnchorEl(evt.currentTarget)
	}

	const handleOnClose = () => {
		setAnchorEl(null)
	}

	const handleMenuItemPress = i => {
		handleOnClose()
		switch(i) {
			case menuItem.businesses:
				break
			case menuItem.contentCreators:
				break
			case menuItem.removeAds:
				break
			case menuItem.jobs:
				break
			case menuItem.logout:
				firebase.auth().signOut()
				.then(() => window.location.reload())
				break
			case menuItem.contactUs:
				window.open('mailto:contact@joinaudience.com', '_blank')
				break
			case menuItem.investors:
				window.open(`mailto:invest@joinaudience.com`, '_blank')
				break
			case menuItem.submitMusic:
				window.open('https://docs.google.com/forms/d/e/1FAIpQLSdAcct7y11p0x22Sq1vvCVirHIgIU7os7mTqIdBrTBuSp-SiA/viewform?usp=sf_link','_blank')
				break
			case menuItem.privacy:
				window.open(`https://www.privacypolicies.com/live/aa97fcff-9986-4be6-8805-e829ee4c0bbe`, '_blank')
			case menuItem.curator:
				window.open('https://docs.google.com/forms/d/e/1FAIpQLScx9Su_aTeSthTV3BrYdv1MqRSkT_ZI0lGKdkLZKs0aELYnew/viewform?usp=sf_link', '_blank')
				break
			default:
				//Do nothing
		}
	}

	React.useEffect(() => {
		if(userDataInit){
			if(userData.completedTutorial == null){
				setShowTutorial(true)
				setIsMuted(true)
			} else if(userData.username == null){
				//setShowProfile(true)
			} 
		}
	},[userData.username, userData.completedTutorial, userDataInit, setIsMuted])

	/*React.useEffect(() => {
		let timer
		let intervalTimer
		if(stationID !== "earn" && stationID != null && userData.completedTutorial == true){
			setTimeTarget(Date.now()+30000)
			timer = setInterval(() => {
				if(userData.coins <= 0){ 
					setShowAd(true)
				} else { 
					setInGrace(false)
					setTimeTarget(Date.now()+60000)
					musicListen({scheduledContentID: currentContent.scheduledContentID})
					setUserData(prevState => {
						return { 
							...prevState, 
							coins: prevState.coins - 1 
						}
					})
					intervalTimer = setInterval(() => {
						musicListen({scheduledContentID: currentContent.scheduledContentID})
						setUserData(prevState => {
							return { 
								...prevState, 
								coins: prevState.coins - 1 
							}
						})
					},60000)
	
				}
			}, 30000)
		}
		return () => {
			if(timer) { clearTimeout(timer) }
			if(intervalTimer) { clearInterval(intervalTimer) }
		}
	},[stationID, currentContent, userData.completedTutorial, timeTarget])*/

	/*React.useEffect(() => {
		setTimeTarget(Date.now() + 30000) 
		setInGrace(true)
	},[stationID, userData.completedTutorial,showAd])

	React.useEffect(() => {
		let timer
		if(userData.completedTutorial && !showAd){
			timer = setInterval(() => {
				if(userData.coins <= 0){ 
					setShowAd(true)
				} else {
					setInGrace(false)
					musicListen({scheduledContentID: stationID === "whitenoise" ? "whitenoise" : currentContent.scheduledContentID})
					setUserData(prevState => {
						return { 
							...prevState, 
							coins: prevState.coins - showAd ? 1 : -1
						}
					})
					setTimeTarget(prevState => prevState + 60000)
				}
			}, inGrace ? 30000 : 60000)
		}
		return(() => {
			if(timer){ clearInterval(timer) }
		})
	},[timeTarget, showAd])

	React.useEffect(() => setTic(!tic),[userData])

	React.useEffect(() => {
		let timer = setInterval(() => {
			setProgress(Math.ceil(((1 - (timeTarget - Date.now())/(inGrace ? 30000 : 60000)))*100))
		}, 100)
		return () => clearInterval(timer)
	},[inGrace,timeTarget])*/

	return (
		<View style={s.container}>
			{/*<TouchableOpacity style={{flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: '100%'}} onPress={() => history.push('/earn')}>
				<View style={{width: 40, height: 40, margin: 10, hidden: (stationID == "earn" || userData.completedTutorial == null || showAd)  ? true: false}}>
					<CircularProgress variant="static" value={progress} style={{color: userData.coins <= 0 ? "red" : "gold"}} />
					<View style={{width: '100%',height: '100%', position: 'absolute', alignItems: 'center', justifyContent:'center'}}>
						<Text style={{textAlign: 'center', color: userData.coins < 1 ? "red" : "gold"}}>{'-1ⵙ'}</Text>
					</View>
				</View>
				<Text style={s.currency}>{(userData.coins || 0) + 'ⵙ'}</Text>
			</TouchableOpacity>*/}
			<UserInfo name={name} color={color} bitmojiUrl={bitmojiUrl} onPress={()=>setShowProfile(true)} />
			<View style={{height: 40, marginLeft: 30, width:0, alignItems:'center', justifyContent: 'center'}}>
				<IconButton fullWidth onClick={handleSettingsPress}>
					<SettingsIcon style={{fontSize: 30, color: 'white'}}/>
				</IconButton>
			</View>
			<View style={{height: 40, marginLeft: 44, marginRight: 30, width:0, alignItems:'center', justifyContent: 'center'}}>
				<IconButton fullWidth onClick={()=> window.location.reload()}>
					<RefreshIcon style={{fontSize: 30, color: 'white'}}/>
				</IconButton>
			</View>
			<Menu
				id="settings-menu"
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleOnClose}
				//anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
				transformOrigin={{vertical: 'top', horizontal: 'right'}}
			>
				<MenuItem onClick={()=>handleMenuItemPress(menuItem.submitMusic)}>Submit Music</MenuItem>
				<MenuItem onClick={()=>handleMenuItemPress(menuItem.curator)}>Become a Curator</MenuItem>
				<MenuItem onClick={()=>handleMenuItemPress(menuItem.contactUs)}>Contact Us</MenuItem>
				<MenuItem onClick={()=>handleMenuItemPress(menuItem.privacy)}>Privacy Policy</MenuItem>
				<MenuItem onClick={()=>handleMenuItemPress(menuItem.logout)}>Logout</MenuItem>
			</Menu>
			{/*<Tutorial display={showTutorial} dismiss={() => setShowTutorial(false)} />*/}
			
		</View>
	)
}
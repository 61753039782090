import React from 'react'
import { View, StyleSheet } from 'react-native'
import { defaultColor } from '../../utils/GlobalStyles'

const s = StyleSheet.create({
	container: {
		width: '100%',
		backgroundColor: 'black',
		height: 1
	},
	progressBar: {
		height: '100%',
		backgroundColor: defaultColor
	}
})

//Progress Bar
export default ({progress}) => {
	const progressPercentString = `${progress}%`
	return (
		<View style={s.container}>
			<View style={[s.progressBar, {width: progressPercentString}]}/>
		</View>
	)
}
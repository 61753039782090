import { createContext } from 'react'

export const BoosterDataContext = createContext({
	boosterData:{},
	setBoosterData:()=>{},
})

export const UserContext = createContext({
	user: null,
	subdomainInfo: {},
	userData: {},
	setUserData: () => {},
	userDataInit: false,
	initializing: true,
	setUser: () => {},
	userInit: false
})

export const NavigationContext = createContext({
	loadingMsg: 'Loading ...',
	setLoadingMsg: () => {},
	showLoading: true,
	setShowLoading: () => {},
	location: 'Auth',
	navigateTo: () => {},
	isBusiness: false,
	setIsBusines: () => {}
})

export const ContentContext = createContext({
	contentName: 'ChoiceContent',
	setContent: () => {},
})

export const TimeContext =  createContext({
	serverTimestamp: 0,
	serverCheckTimestamp: 0,
	time: 0,
	setTime: () => {},
})

export const StatusContext = createContext({
	status: null,
	setStatus: ()=>{}
})

export const VisualContext = createContext({
	showStatic: false,
	setShowStatic: ()=>{},
	visualID: null,
	setVisualID: ()=>{}
})

export const StationContext = createContext({
	stationIsLoading: true,
	setStationIsLoading: ()=>{},
	showFlipper: false,
	setShowFlipper: ()=>{},
	stationData: {},
	setStationData: ()=>{},
	isUp: null,
	setIsUp:()=>{},
	stationID: null,
	setStationID: () => {},
	joinTimestamp: Date.now(),
	setJoinTimestamp: ()=>{},
	showEarn:false,
	setShowEarn:()=>{}
})

export const AudioContext = createContext({
	isMuted: true,
	setIsMuted: ()=>{},
	masterVol: 0.75,
	setMasterVol: () => {},
	frequency: 25,
	setFrequency: ()  => {}
})

export const AdContext = createContext({
	showAd:false,
	setShowAd: () => {},
	errorMsg: {},
	setErrorMsg: () => {},
})
import React from 'react'
import { Dimensions } from 'react-native'
import { AudioContext, StationContext, UserContext } from './Hooks'
import { View } from 'react-native'
import firebase from '../config/Firebase' 
import { Howl, Howler } from 'howler'
import silence from '../assets/silence_64kb.mp3'

const deck = {
	a: 1,
	b: 2,
}

const TRANSITION_TIME = 1000
const TOLERANCE = 5
const URL = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/123941/Yodel_Sound_Effect.mp3';

var isSafari = false
var ua = navigator.userAgent.toLowerCase(); 
if (ua.indexOf('safari') != -1 && Dimensions.get('window').width < 640){ isSafari = true }
var deckARef //= new Howl({src:[silence],autoplay:true, html5:true})
var deckBRef //= new Howl({src:[silence],autoplay:true, html5:true})
var initUnlocked = false
var unlocked = false
let initSound = new Howl({
	src: silence, 
	onunlock:()=>{
		initUnlocked = true
		console.log('unlocked')
	},
	onplayerror: () => {
		console.log('int play error')
	},
	autoplay: true,
	onplay: () => {
		console.log('playing')
		initUnlocked = true
	}
})

var whiteNoiseNode
var filter
var gainNode
const playNoise = () => {
	var bufferSize = 4096;
	var brownNoise = (function() {
	    var lastOut = 0.0;
	    var node = Howler.ctx.createScriptProcessor(bufferSize, 1, 1);
	    node.onaudioprocess = function(e) {
	        var output = e.outputBuffer.getChannelData(0);
	        for (var i = 0; i < bufferSize; i++) {
	            var white = Math.random() * 2 - 1;
	            output[i] = (lastOut + (0.02 * white)) / 1.02;
	            lastOut = output[i];
	            //output[i] *= 3.5; // (roughly) compensate for gain
	        }
	    }
	    return node;
	})();
	gainNode = Howler.ctx.createGain()
	filter = Howler.ctx.createBiquadFilter()
	filter.type = filter.LOWPASS;
	var minValue = 40;
	var maxValue = Howler.ctx.sampleRate / 2;
	// Logarithm (base 2) to compute how many octaves fall in the range.
	var numberOfOctaves = Math.log(maxValue / minValue) / Math.LN2;
	// Compute a multiplier from 0 to 1 based on an exponential scale.
	var multiplier = Math.pow(2, numberOfOctaves * (25/100 - 1.0));

	filter.frequency.value = maxValue * multiplier;
	  // Connect source to filter, filter to destination.
	brownNoise.connect(gainNode);
	gainNode.connect(filter)
	filter.connect(Howler.ctx.destination)
	//pinkNoise.start()
}

const loadAudio = (_deck, url, seekTo, shouldPlay,callback,errorCallback) => {
	console.log("loadAudio")
	console.log('url: ', url)
	if(_deck == deck.a){
		deckARef = new Howl({
			//autoUnlock: !isSafari,
			src: url,
			html5:true,
			onplayerror: () => {
				console.log('PLAY ERROR')
				//errorCallback()
				deckARef.once('unlock', () => {
					deckARef.seek(seekTo)
					deckARef.play()
					//callback()
				})
			},
			onload: () => {
				console.log('deck a loaded')
				console.log('seekTo: ', seekTo)
				deckARef.seek(seekTo)
			},
			onplay: callback,
			onseek: () => {
				console.log('seeking')
				if(shouldPlay){ deckARef.play() }
			}
		})
	} else {
		deckBRef = new Howl({
			//autoUnlock: !isSafari,
			onplayerror: () => {
				console.log('deck b play error')
			},
			src: url, 
			html5:true,
			onload: () => deckBRef.seek(seekTo)
		})
	}
} 

const playAudio = _deck => _deck == deck.a ? deckARef.play() : deckBRef.play()

const stopAudio = _deck => deck.a ? deckARef.stop() : deckBRef.stop()

const setVol = (_deck, vol) => {
	if(_deck.a && deckARef){
		deckARef.volume(vol)
	} else if (deckBRef) {
		deckBRef.volume(vol)
	}
}

const setMute = isMute => Howler.mute(isMute)

const setRate = (_deck, rate) => deck.a ? deckARef.rate(rate) : deckBRef.rate(rate)
//Music
export default ({ contentList, progress, setProgress, setCurrentContent, refreshContent })=> {
	const { isMuted, setIsMuted, masterVol, setMasterVol, frequency } = React.useContext(AudioContext)
	const { stationID } = React.useContext(StationContext)
	const { userData } = React.useContext(UserContext)
	const { width, height} = Dimensions.get('window')
	//Metadata
	const [deckA, setDeckA] = React.useState({})
	const [deckB, setDeckB] = React.useState({})
	const [aux, setAux] = React.useState({})

	const [tic, setTic] = React.useState(false)

	const [isInitializing, setIsInitializing] = React.useState(true)

	const isTransitioning = React.useRef(false)
	const isTwoPlayingTracks = React.useRef(false)
	const currentDeck = React.useRef(deck.a)
	const tempMasterVolRef = React.useRef(masterVol)

	const loadDeck = (_deck, data, shouldPlay=false) => {
		console.log("loadDeck")
		let callback = () => {
			if(shouldPlay && userData.completedTutorial == true){ setIsMuted(false) }
		}

		let seekTo = 0

		if(data == null || data.metadata == null){
			loadAudio(_deck,silence,seekTo,shouldPlay,callback)
		} else {
			if(shouldPlay){
				seekTo = (data.metadata.fadeOut - (data.endTimestamp - Date.now()))/1000
			} else {
				seekTo = Math.max(data.metadata.fadeIn - TRANSITION_TIME,0)/1000
			}
		}

		if(_deck == deck.a && deckARef != null) {
			deckARef.stop()
			deckARef.unload()
			deckARef = null
		} else if (deckBRef != null) {
			deckBRef.stop()
			deckBRef.unload()
			deckBRef = null
		}

		if(data == null || data.metadata == null){
			loadAudio(_deck,silence,seekTo,shouldPlay,callback)
		} else {
			firebase.storage().ref("tracks/" + data.metadata.filename).getDownloadURL()
			.then(url => loadAudio(_deck,url,seekTo, shouldPlay,callback))
		}

		if(stationID == "whitenoise"){
			playNoise()
		}
	}

	React.useEffect(() => {
		//console.log("muting: ", isMuted)
		if(isMuted){ tempMasterVolRef.current = masterVol }

		let vol = isMuted ? 0 : tempMasterVolRef.current
		//console.log("Set vol: ", vol)
		if( gainNode ) { gainNode.gain.setValueAtTime(isMuted ? 0 : 1, Howler.ctx.currentTime)}
		setMasterVol(vol)
		setMute(isMuted)

	},[isMuted, gainNode])

	React.useEffect(() => {

		if(filter){
			var minValue = 40;
			var maxValue = Howler.ctx.sampleRate / 2;
			// Logarithm (base 2) to compute how many octaves fall in the range.
			var numberOfOctaves = Math.log(maxValue / minValue) / Math.LN2;
			// Compute a multiplier from 0 to 1 based on an exponential scale.
			var multiplier = Math.pow(2, numberOfOctaves * (frequency/100 - 1.0));
			// Get back to the frequency value between min and max.
			filter.frequency.value = maxValue * multiplier;
		}
	},[frequency])

	React.useEffect(() => {
		//console.log("Init: ", isInitializing)
		
		if(isInitializing){ 
			if (contentList && contentList.length > 0 && contentList[0].stationID === stationID) {
			//console.log("MusicContent (contentList): ", contentList)
				console.log('isSafari: ', isSafari)
				let _contentList
				if((isSafari && isMuted) || (initUnlocked == false && unlocked == false)){
					setIsMuted(true)
					unlocked = true
					currentDeck.current = deck.a
					_contentList = contentList.filter(item => item.endTimestamp > Date.now())
					setCurrentContent(_contentList[0])
					setDeckA(_contentList[0])
					setDeckB(_contentList[1])
				} else {
					setIsInitializing(false)
					currentDeck.current = deck.a
					_contentList = contentList.filter(item => item.endTimestamp > Date.now())
					setCurrentContent(_contentList[0])
					setDeckA(_contentList[0])
					setDeckB(_contentList[1])
					isTransitioning.current = false
					
					//console.log("INITIALIZING NOW")
					loadDeck(deck.a,_contentList[0], true)
					loadDeck(deck.b,_contentList[1])
				}
			} else if(stationID == "whitenoise" && !isMuted) {
				setIsInitializing(false)
				playNoise()
			}
		} 
	},[isInitializing, contentList,isMuted, initUnlocked])

	React.useEffect(() => {
		if(!isInitializing && contentList && contentList.length > 0){	
			const _contentList = contentList.filter(item => item.endTimestamp > Date.now())
			if(currentDeck.current === deck.a) {
				setDeckB(_contentList[1])
				loadDeck(deck.b, _contentList[1], false)
			} else {
				setDeckA(_contentList[1])
				loadDeck(deck.a,_contentList[1],false)
			}
		}
	},[contentList])
	
	const automation = () => {
		if(stationID == "whitenoise") { 
			//setProgress(progress == 0 ? 1 :0)
			return 
		}
		let playingDeck = Object.assign({},currentDeck.current === deck.a ? deckA : deckB)		
		let timeLeft = playingDeck.endTimestamp ? (playingDeck.endTimestamp - Date.now()) : null
		
		if(timeLeft !== null && timeLeft >= 0 && timeLeft <= TRANSITION_TIME+1000) {
			//console.log("In transition")
			let nextDeck = Object.assign({},currentDeck.current === deck.a ? deckB : deckA)
			let transitionTime = Math.max(100,Math.min(TRANSITION_TIME,nextDeck.metadata.fadeIn))

			if(!isTransitioning.current){
				//console.log("start transition timer: ", Date.now())
				//console.log("fadeIn: ", nextDeck.metadata.fadeIn)
				//console.log("timeLeft: ", timeLeft)
				setTimeout(()=>{
					//console.log("play track: ", Date.now())
					isTwoPlayingTracks.current = true
					//console.log('PLAY AUDIO')
					if(currentDeck.current === deck.a && deckBRef != null){
						deckBRef.play()
					} else if(deckARef != null) {
						deckARef.play()
					}
					//let _timeLeft = playingDeck.metadata.fadeOut - currentRef.currentTime * 1000
					let _timeLeft = playingDeck.endTimestamp - Date.now()
					//console.log("Offset: "playingDeck.endTimestamp - Date.now()
					//setTic(!tic)
				},Math.max(1,timeLeft - Math.min(nextDeck.metadata.fadeIn, TRANSITION_TIME)))// -150))	
			}

			isTransitioning.current = true
	
			let i = Math.max(0,Math.min(1,timeLeft/transitionTime))

			let nextGain = Math.cos(i*0.5*Math.PI)
			let currGain = Math.cos((1.0-i)*0.5*Math.PI)
			if(deckARef) { deckARef.volume((currentDeck.current === deck.a ? currGain : nextGain) * masterVol) }
			if(deckBRef) { deckBRef.volume((currentDeck.current === deck.b ? currGain : nextGain) * masterVol) }
		} else {
			if(isTransitioning.current && isTwoPlayingTracks.current){
				
				isTransitioning.current = false
				isTwoPlayingTracks.current = false
				setCurrentContent(Object.assign({},currentDeck.current === deck.a ? deckB : deckA))
				currentDeck.current = currentDeck.current == deck.a ? deck.b : deck.a
				refreshContent()
			}
			if(!isTwoPlayingTracks.current && !isTransitioning.current){
				isTransitioning.current = false	
				if(deckARef) { deckARef.volume(currentDeck.current === deck.a ? masterVol : 0) }
				if(deckBRef) { deckBRef.volume(currentDeck.current === deck.b ? masterVol : 0) }
			}	
		}
		if(playingDeck.endTimestamp){
			let pos = Math.max(0, playingDeck.metadata.duration - (playingDeck.endTimestamp - Date.now()))/playingDeck.metadata.duration * 100
			setProgress(pos)
		}

		/*if (playingDeck.metadata && deckARef != null && deckBRef != null && (currentDeck.current === deck.a ? deckARef.playing() : deckBRef.playing())){
			let currentTime = currentDeck.current === deck.a ? deckARef.seek() * 1000 : deckBRef.seek() * 1000
			let _timeLeft = playingDeck.metadata.fadeOut - currentTime
			let estTimeleft = playingDeck.endTimestamp - Date.now()
			//console.log('Read pos: ', currentTime)
			//.log('Real time: ', estTimeleft)
			if(estTimeleft - _timeLeft > TOLERANCE){
				//console.log("down")
				if(currentDeck.current == deck.a){
					if(deckARef.rate() !== 0.99){ deckARef.rate(0.99)}
				} else {
					if(deckBRef.rate() !== 0.99){ deckBRef.rate(0.99)}
				}
			} else if (estTimeleft - _timeLeft < -1 * TOLERANCE){
				if(currentDeck.current == deck.a){
					if(deckARef.rate() !== 1.01){ deckARef.rate(1.01)}
				} else {
					if(deckBRef.rate() !== 1.01){ deckBRef.rate(1.01)}
				}
				//console.log("up")
	
			} else {	
				//console.log("sit")
				if(currentDeck.current == deck.a){
					if(deckARef.rate() !== 1.00){ deckARef.rate(1.00)}
				} else {
					if(deckBRef.rate() !== 1.00){ deckBRef.rate(1.00)}
				}
			}
		}*/
	}
	
	React.useEffect(() => {
		let timer
		automation() 
		timer = setTimeout(()=> setTic(!tic),100)
		return () => {
			if(timer) { clearInterval(timer) }
		}
	},[tic,stationID])

	React.useEffect(() => {
		//console.log("StationID: ", stationID)
		return () => {
			Howler.stop()
			Howler.unload()
			deckARef = null
			deckBRef = null
			setIsInitializing(true)
		}
	},[stationID])

	return null
}
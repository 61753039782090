import React from 'react'
import { View, TouchableOpacity, StyleSheet, Image, Text, TouchableWithoutFeedback } from 'react-native'
import { StationContext, AudioContext, UserContext } from '../../utils/Hooks'
import VolumeOffIcon from '@material-ui/icons/VolumeOff'
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import ThumbDownIcon from '@material-ui/icons/ThumbDown'
import { defaultColor } from '../../utils/GlobalStyles'
import firebase from '../../config/Firebase'
import MusicOffIcon from '@material-ui/icons/MusicOff';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import MicOffIcon from '@material-ui/icons/MicOff';
import clapImg from '../../assets/applause.png'
//import { useHistory } from 'react-router-dom'
//import pop1 from '../../assets/pop1.mp3'

const s = StyleSheet.create({
	container: {
		flex:1,
		width: '100%',
		height: 64,
		flexDirection: 'row',
		justifyContent: 'space-around',
		alignItems: 'center'
	},
	buttonContainer: {
		height: '100%',
		flexDirection: 'row',
		flex:1,
		alignItems: 'center',
		justifyContent: 'space-between',
	},
	thumbCountText: {
		color: 'white',
		fontWeight: 'bold',
		fontSize: 12,
	},
	thumbCountContainer: {
		flexDirection: 'row',
		width: 60,
		alignItems: 'center',
		justifyContent: 'center'
	}
})

const thumbs = {
	clear: 0,
	up: 1,
	down: 2,
}
//let buffer
//const URL = 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/123941/Yodel_Sound_Effect.mp3';

//PlayerControls
export default ({ networkArtUrl, currentContent, engageList }) => {
	//const history = useHistory()

	const { stationID, setIsUp, showEarn } = React.useContext(StationContext)
	const { isMuted, setIsMuted } = React.useContext(AudioContext)
	const { user, userData } = React.useContext(UserContext)

	const [thumb, setThumb] = React.useState(thumbs.clear)
	const [thumbsDownCount, setThumbsDownCount] = React.useState(0)
	const [thumbsUpCount, setThumbsUpCount] = React.useState(0)

	const handleChannelPress = _isUp => {
		setIsUp(_isUp)
	}

	const handleThumbPress = _isUp => {
		//console.log('thumb pressed up: ', _isUp)
		if(thumb === thumbs.clear && currentContent.scheduledContentID){
			//console.log("THumber userID: ", user.uid)
			//console.log("Username: ", userData.username)
			setThumb(_isUp ? thumbs.up : thumbs.down)
			_isUp ? setThumbsUpCount(thumbsUpCount + 1) : setThumbsDownCount(thumbsDownCount + 1)
			let engageID = `thumbs: ${user.uid}:${currentContent.scheduledContentID}`
			firebase.firestore().collection("engage").doc(engageID).set({
				payload: { 
					selectedUp: _isUp,
					title: currentContent.metadata.name || currentContent.metadata.title,
					artist: currentContent.metadata.artist,
					albumArtUrl: currentContent.metadata.albumArtUrl,
					scheduledContentID: currentContent.scheduledContentID,
				},
				stationID: stationID,
				userColor: userData.userColor,
				timestamp: Date.now(),
				type: "thumbs",
				engageID: engageID,
				userID: user.uid,
				username: userData.username || "Unknown",
			})
		}
	}

	const handleVolumeOffPress = e => {
		console.log("Mute pressed")
    	setIsMuted(!isMuted)
    	window.navigator.vibrate(10)
	}

	React.useEffect(() => {
		//Load audio player
		setThumbsDownCount(engageList.filter(i => i.payload.selectedUp === false && i.payload.scheduledContentID === currentContent.scheduledContentID).length)
		setThumbsUpCount(engageList.filter(i => i.payload.selectedUp === true && i.payload.scheduledContentID === currentContent.scheduledContentID).length)
		let myEngagement = engageList.filter(i => i.payload.scheduledContentID === currentContent.scheduledContentID && i.userID === user.uid)[0]
		//console.log("My engagement: ", engageList.filter(i => i.payload.scheduledContentID == currentContent.scheduledContentID && i.userID == user.uid))
		//console.log("Current Content", currentContent)
		//console.log("myEngagement: ", myEngagement)
		if(myEngagement){
			setThumb(myEngagement.payload.selectedUp ? thumbs.up : thumbs.down)
		} else {
			setThumb(thumbs.clear)
		}
	},[engageList, currentContent, user.uid])

	return (
		<View style={s.container}>
			<View style={s.thumbCountContainer}>{thumb !== thumbs.clear && <Text style={[s.thumbCountText, { color:  thumb === thumbs.down ? defaultColor : 'white'}]}>{thumbsDownCount}</Text>}</View>
			<View style={s.buttonContainer}>
				<TouchableWithoutFeedback>
					<Image style={{resizeMode:'contain', height:26, width: 26, opacity: 0.2}} source={clapImg} />
				</TouchableWithoutFeedback>
				<TouchableOpacity disabled={showEarn} onPress={() => handleChannelPress(false)}><ArrowDropDownIcon style={{color:'white', fontSize: 34, opacity: showEarn ? 0.3 : 1}}/></TouchableOpacity>
				<TouchableOpacity onPress={handleVolumeOffPress}>
					{isMuted ? 
						<MusicOffIcon style={{color:defaultColor, fontSize: 30}}/>
						: <MusicNoteIcon style={{color: 'white', fontSize: 30}} />
					}
				</TouchableOpacity>
				<TouchableOpacity disabled={showEarn} onPress={() => handleChannelPress(true)}><ArrowDropUpIcon style={{color: 'white', fontSize: 34, paddingTop: 6, opacity: showEarn ? 0.3 : 1}}/></TouchableOpacity>
				<TouchableOpacity>
					<MicOffIcon style={{fontSize: 28,opacity: 0.2, color: 'white'}}/>
				</TouchableOpacity>
			</View>
			<View style={s.thumbCountContainer}>{thumb !== thumbs.clear && <Text style={[s.thumbCountText, {color: thumb === thumbs.up ? defaultColor : 'white'}]}>{thumbsUpCount}</Text>}</View>
		</View>
	)
}
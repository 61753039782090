import React from 'react'
import { View, Text, StyleSheet, Dimensions } from 'react-native'
import StatusBar from './StatusBar'
import PlayerControls from './PlayerControls'
import NowPlaying from './NowPlaying'
import ProgressBar from './ProgressBar'
import firebase from '../../config/Firebase'

const s = StyleSheet.create({
	container: {
		width:'100%',
		backgroundColor: 'rgba(0,0,0,0.3)',
		backdropFilter: 'blur(10px)'
	},
	nowPlayingContainer: {
		maxWidth: '100%',
		alignItems: 'center'
	},
})

//BottomBar
export default ({ currentContent, progress, networkArtUrl, engageList}) => {
	const { height, width } = Dimensions.get('window')
	let title, artist, albumArtUrl, link
	const hasContent = currentContent != null && currentContent.metadata != null

	title = hasContent ? currentContent.metadata.name : null
	artist = hasContent ? currentContent.metadata.artist : null
	albumArtUrl = hasContent ? currentContent.metadata.albumArtUrl : null
	link = hasContent ? currentContent.metadata.soundcloudLink : null
	
	return (
		<View style={s.container}>
			<View style={[s.nowPlayingContainer,{flexDirection: width >= 640 ? 'row' : 'column-reverse'}]}>
				<NowPlaying title={title} artist={artist} albumArtUrl={albumArtUrl} link={link}/>
				<PlayerControls currentContent={currentContent} networkArtUrl={networkArtUrl} engageList={engageList}/>
				{width >= 900 && <View style={{flex:1}} />}
			</View>
			<ProgressBar progress={progress}/>
			<StatusBar />
		</View>
	)
}
import React from 'react'
import firebase, { databaseRef } from '../../config/Firebase'
import content from '../../content'
import Ads from '../../utils/Ads'
import Layout from '../Layout'
import LoadingOverlay from './LoadingOverlay'
import { StatusContext, UserContext, StationContext } from '../../utils/Hooks'
import { useParams } from 'react-router-dom'


function parseQuery(queryString) {
    var query = {};
    var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (var i = 0; i < pairs.length; i++) {
        var pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
    return query;
}

const Navigation = () => {
    let { slug } = useParams()

  	const [location, navigateTo] = React.useState('Auth')
  	const [showLoading, setShowLoading] = React.useState(true)
  	const [loadingMsg, setLoadingMsg] = React.useState('Loading ...')
    const [status, setStatus] = React.useState()
    const [subdomain, setSubdomain] = React.useState()
    const [isBusiness, setIsBusiness] = React.useState(false)

    const [timeInit, setTimeInit] = React.useState(true) //TESTING change back
    const [userInit, setUserInit] = React.useState(false)
  	const [userDataInit, setUserDataInit] = React.useState(false)
    const [subInit, setSubInit] = React.useState(false)
    
    const [user, setUser] = React.useState(null)
    const [userData, setUserData] = React.useState({})
    const [subdomainInfo, setSubdomainInfo] = React.useState({})
    const [data, setData] = React.useState({})

    const [stationIsLoading, setStationIsLoading] = React.useState(true)
    const [showFlipper, setShowFlipper] = React.useState(false)
    const [stationID, setStationID] = React.useState()
    const [stationData,setStationData] = React.useState({})
    const [isUp, setIsUp] = React.useState(null)
    const [joinTimestamp, setJoinTimestamp] = React.useState(Date.now() - 24*60*60*1000)
    const [showEarn, setShowEarn] = React.useState(false)

    //const navVal = { location, navigateTo, showLoading, setShowLoading, loadingMsg, setLoadingMsg, isBusiness, setIsBusiness }
    const statusVal = { status, setStatus }
    const userVal = { user, userData, userDataInit, setUserData, setUser, subdomainInfo, userInit }
    //const dataVal = { data, setData }
    const stationVal = { stationData, setStationData, stationIsLoading, setStationIsLoading, showFlipper, setShowFlipper, isUp, setIsUp, stationID, setStationID, joinTimestamp, setJoinTimestamp, showEarn, setShowEarn }

  	const CurrentContent = content[location]

    React.useEffect(() => {
      console.log("Slug: " + slug)
      if (slug === "submissions") {
        window.location.href = "http://docs.google.com/forms/d/e/1FAIpQLSdAcct7y11p0x22Sq1vvCVirHIgIU7os7mTqIdBrTBuSp-SiA/viewform?usp=sf_link"
      } else if (slug === "artists") {
        window.location.href = "https://www.notion.so/audience/How-Audience-Works-3f0e442eb9c84f838fc260e10a554477"
      } else if (slug === "curators") {
        window.location.href = "https://docs.google.com/forms/d/e/1FAIpQLScx9Su_aTeSthTV3BrYdv1MqRSkT_ZI0lGKdkLZKs0aELYnew/viewform?usp=sf_link"
      } else if (slug === "" || slug == null ) {
        let queryObj = parseQuery(window.location.search)
        let _stationName = queryObj.state || "chillhop"
        window.location.href = window.location.protocol + "//" + window.location.host + "/" + _stationName
      } else {
        firebase.firestore().collection('stations').where('slug', '==', slug).get()
        .then(snap => {
            if(snap.empty){
              //Throw error
            } else {
              let _data = snap.docs[0].data()
              //TO-DO: Include network in query parameter when new networks are added
              setStationID(_data.stationID)
              document.title = _data.name
            }
        })
      }
    },[slug])

    React.useEffect(() => {
      let ref
      if(userData.username && stationID){
        ref = databaseRef.ref('viewers/' + stationID + '/' + user.uid)
        let body = { username: userData.username }
        if (userData.bitmojiUrl) { body.bitmojiUrl = userData.bitmojiUrl }
        databaseRef.ref('.info/connected').on('value', snapshot => {
          if(snapshot.val() === true){
            ref.onDisconnect().remove()
            .then(() => ref.set(body))
          }
        })
      }
      return () => {
        if(ref){
          ref.off()
          ref.remove()
        }
      }
    },[stationID, userData.username, userData.bitmojiUrl, user])

    React.useEffect(() => {
      setTimeout(() => setTimeInit(true),1000)

      firebase.auth().onAuthStateChanged(user => {   

        if(user){
          console.log("User: ", user)
          setUserInit(true)
          setUser(user)
          navigateTo(isBusiness ? 'Business' : 'Consumer')
        } else {
          firebase.auth().signInAnonymously()
          
          //setUser(null)
          //navigateTo('Auth')
        }
      })

      if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
        var email = window.localStorage.getItem('emailForSignIn');
        if (!email) {
          email = window.prompt('Please provide your email for confirmation');
        }
        firebase.auth().signInWithEmailLink(email, window.location.href)
          .then(function(result) {
            window.localStorage.removeItem('emailForSignIn');
          })
          .catch(function(error) {
            // Some error occurred, you can inspect the code: error.code
            // Common errors could be invalid email and invalid or expired OTPs.
          });
      }
    },[isBusiness])
  	

    React.useEffect(() => {
      let ref = firebase.database().ref('subdomains/'+ subdomain)
      if (subdomain){
        console.log('subdomain')
        ref.on('value', snapshot => {
          if(snapshot && snapshot.val()){
            setSubdomainInfo(snapshot.val())
          }
          console.log('subInit')
          setSubInit(true)
        })
      }
      return () => ref.off()
    },[subdomain])

    React.useEffect(() => {
  		if(timeInit && userInit && location !== "Auth") {
        setShowLoading(false)
        setStationIsLoading(false)
      } else if (location === "Auth"){
        setShowLoading(true)
      }
  	},[timeInit, userInit, location, userDataInit, subInit])

    React.useEffect(()=> {
        let url = window.location.host //get from browser function
        let parts = url.split('.')
        let subdomain = parts[0] // get just the first part
        setSubdomain(subdomain)
    },[])

    React.useEffect(() =>{
        setUserDataInit(false)
        let uid = user ? user.uid : null
        const userDataListener = firebase.firestore().collection('users').doc(uid || "x").onSnapshot(doc => {
            setUserData(doc.data() || {})
            setUserDataInit(true)
        })
      return userDataListener
    },[user])

  	return (
      <UserContext.Provider value={userVal}>
      <StationContext.Provider value={stationVal}>
      <StatusContext.Provider value={statusVal}>
      <Ads>
    			<Layout location={location}>
              <CurrentContent />
    			</Layout>
  			  <LoadingOverlay display={showLoading || stationIsLoading } text={loadingMsg} />
      </Ads>
      </StatusContext.Provider>
      </StationContext.Provider>
      </UserContext.Provider>
   	)
}

export default Navigation
import React from 'react'
import { View, Text, Image, StyleSheet} from 'react-native'
import { makeStyles } from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import { defaultColor } from '../../utils/GlobalStyles'

const useStyles = makeStyles({
	chip: {
		backgroundColor: 'gray',
		color: 'white',
		fontWeight: 'bold',
		minWidth: 0
	}
})

const AvatarPill = ({name, color="gray", bitmojiUrl, size="small"}) => {
	const classes = useStyles()
	return (
		<Chip size={size} variant={size === "medium" ? "outlined" : undefined} label={name} style={{backgroundColor: color, borderColor: 'white'}} className={classes.chip} avatar={<Avatar src={bitmojiUrl}/>} />
	)
}

export default AvatarPill